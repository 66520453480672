.color-main-container span{
    font-size: 10px;
}
.color-main-container {
    display: flex;
}
.d_flex_color{
    display: flex;
}

.color-popup-highlight{
    opacity: .9;
    top: 45px !important;
    left: 480px !important;
}
.colors_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 6px 0;
    margin: 0px 10px 0px 0px;
}
.colors_color {
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-right: 4px;
    margin-bottom: 4px;
    border-radius: 22px;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center
}
.crossIcon{
    cursor: pointer;
    position: absolute;
    display: flex;
    left: 170px;
    top: 2px;
}
.toggle_bg_Btn{
    /*padding: 5px;*/
    /*margin-bottom: 5px;*/
}
.toggle_bg_Btn > .ant-switch{
    height: 15px!important;
    max-width: 18px!important;
}
.toggle_bg_Btn > .ant-switch > .ant-switch-handle{
    top: 1.8px !important;
    height: 12px!important;
    width: 12px!important;
}
.toggle_bg_Btn span{
    margin-right: 10px;
}
@media screen and (max-width: 992px) {
    .color-main-container{
    }
    .color-popup-highlight{
        top: 50px !important;
    }
    .color-popup-ind{
        top: 50px !important;
    }

}