.ant-modal-title {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 992px){
        width: 50px;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
    }
}
