
//////Added by Khalid Abbas


.sprites-container{
    width: 100%;
    display: block;
    justify-content: space-evenly;
    margin-top: 5px;
    max-height: calc(100vh - 240px);
    overflow-y: auto;
    overflow-x: hidden;
}
.tacticsboard_formated_title{
    color: #FFFFFF;
    padding: 5px;
}
.content-center{
    display: flex;
    justify-content: center;
    align-content: center;
}
.sprites-container_rotation{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
}
.sprites-container_rotation button{
    background: transparent !important;
    height: 25px;
    width: 25px;
}
.sprites-container_rotation button:hover{
    background: transparent !important;
}
// Light Blue
.lb {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/LightBlue.webp');
    background-repeat: no-repeat;
    display: block;
}
.lb-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -3px;
}

.lb-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.lb-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.lb-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.lb-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.lb-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.lb-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.lb-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.lb-B0000 {
    width: 65px;
    height: 60px;
    background-position: -476px -3px;
}

.lb-B0001 {
    width: 65px;
    height: 60px;
    background-position: -550px -2px;
}

.lb-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -73px;
}

.lb-B0003 {
    width: 65px;
    height: 60px;
    background-position: -88px -73px;
}

.lb-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.lb-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.lb-B0006 {
    width: 65px;
    height: 60px;
    background-position: -316px -75px;
}

.lb-B0007 {
    width: 65px;
    height: 60px;
    background-position: -393px -75px;
}

.lb-C0000 {
    width: 60px;
    height: 54px;
    background-position: -460px -72px;
}

.lb-C0001 {
    width: 60px;
    height: 54px;
    background-position: -525px -75px;
}

.lb-C0002 {
    width: 60px;
    height: 54px;
    background-position: -455px -139px;
}

.lb-C0003 {
    width: 60px;
    height: 54px;
    background-position: -525px -139px;
}

.lb-C0004 {
    width: 60px;
    height: 54px;
    background-position: -12px -200px;
}

.lb-C0005 {
    width: 60px;
    height: 54px;
    background-position: -81px -200px;
}

.lb-C0006 {
    width: 60px;
    height: 54px;
    background-position: -147px -199px;
}

.lb-C0007 {
    width: 60px;
    height: 54px;
    background-position: -215px -200px;
}

.lb-D0000 {
    width: 60px;
    height: 47px;
    background-position: -291px -198px;
}

.lb-D0001 {
    width: 60px;
    height: 47px;
    background-position: -363px -199px;
}

.lb-D0002 {
    width: 60px;
    height: 47px;
    background-position: -434px -197px;
}

.lb-D0003 {
    width: 60px;
    height: 47px;
    background-position: -500px -199px;
}

.lb-D0004 {
    width: 60px;
    height: 47px;
    background-position: -292px -258px;
}

.lb-D0005 {
    width: 60px;
    height: 47px;
    background-position: -361px -257px;
}

.lb-D0006 {
    width: 60px;
    height: 47px;
    background-position: -429px -255px;
}

.lb-D0007 {
    width: 60px;
    height: 47px;
    background-position: -499px -254px;
}

.lb-E0000 {
    width: 42px;
    height: 60px;
    background-position: -565px -203px;
}

.lb-E0001 {
    width: 42px;
    height: 60px;
    background-position: -5px -273px;
}

.lb-E0002 {
    width: 42px;
    height: 60px;
    background-position: -57px -273px;
}

.lb-E0003 {
    width: 42px;
    height: 60px;
    background-position: -109px -273px;
}

.lb-E0004 {
    width: 42px;
    height: 60px;
    background-position: -161px -273px;
}

.lb-E0005 {
    width: 42px;
    height: 60px;
    background-position: -213px -273px;
}

.lb-E0006 {
    width: 42px;
    height: 60px;
    background-position: -565px -273px;
}

.lb-E0007 {
    width: 42px;
    height: 60px;
    background-position: -5px -343px;
}

.lb-F0000 {
    width: 63px;
    height: 60px;
    background-position: -279px -317px;
}

.lb-F0001 {
    width: 63px;
    height: 60px;
    background-position: -347px -317px;
}

.lb-F0002 {
    width: 63px;
    height: 60px;
    background-position: -423px -316px;
}

.lb-F0003 {
    width: 63px;
    height: 60px;
    background-position: -494px -317px;
}

.lb-F0004 {
    width: 63px;
    height: 60px;
    background-position: -57px -387px;
}

.lb-F0005 {
    width: 63px;
    height: 60px;
    background-position: -137px -387px;
}

.lb-F0006 {
    width: 63px;
    height: 60px;
    background-position: -212px -387px;
}

.lb-F0007 {
    width: 63px;
    height: 60px;
    background-position: -282px -387px;
}

.lb-G0000 {
    width: 58px;
    height: 60px;
    background-position: -565px -343px;
}

.lb-G0001 {
    width: 58px;
    height: 60px;
    background-position: -360px -413px;
}

.lb-G0002 {
    width: 58px;
    height: 60px;
    background-position: -425px -412px;
}

.lb-G0003 {
    width: 58px;
    height: 60px;
    background-position: -491px -411px;
}

.lb-G0004 {
    width: 58px;
    height: 60px;
    background-position: -553px -413px;
}

.lb-G0005 {
    width: 58px;
    height: 60px;
    background-position: -5px -483px;
}

.lb-G0006 {
    width: 58px;
    height: 60px;
    background-position: -73px -483px;
}

.lb-G0007 {
    width: 58px;
    height: 60px;
    background-position: -141px -483px;
}

.lb-H0000 {
    width: 46px;
    height: 60px;
    background-position: -209px -483px;
}

.lb-H0001 {
    width: 46px;
    height: 60px;
    background-position: -265px -483px;
}

.lb-H0002 {
    width: 46px;
    height: 60px;
    background-position: -321px -483px;
}

.lb-H0003 {
    width: 46px;
    height: 60px;
    background-position: -377px -483px;
}

.lb-H0004 {
    width: 46px;
    height: 60px;
    background-position: -433px -483px;
}

.lb-H0005 {
    width: 46px;
    height: 60px;
    background-position: -489px -483px;
}

.lb-H0006 {
    width: 46px;
    height: 60px;
    background-position: -545px -483px;
}

.lb-H0007 {
    width: 46px;
    height: 60px;
    background-position: -5px -553px;
}

.lb-K0000 {
    width: 60px;
    height: 25px;
    background-position: -71px -553px;
}

.lb-K0001 {
    width: 60px;
    height: 25px;
    background-position: -131px -553px;
}

.lb-K0002 {
    width: 60px;
    height: 25px;
    background-position: -201px -553px;
}

.lb-K0003 {
    width: 60px;
    height: 25px;
    background-position: -271px -553px;
}

.lb-K0004 {
    width: 60px;
    height: 25px;
    background-position: -341px -553px;
}

.lb-K0005 {
    width: 60px;
    height: 25px;
    background-position: -411px -553px;
}

.lb-K0006 {
    width: 60px;
    height: 25px;
    background-position: -490px -553px;
}

.lb-K0007 {
    width: 60px;
    height: 25px;
    background-position: -562px -553px;
}

.lb-L0000 {
    width: 33px;
    height: 60px;
    background-position: -5px -413px;
}

.lb-L0001 {
    width: 33px;
    height: 60px;
    background-position: -619px -5px;
}

.lb-L0002 {
    width: 33px;
    height: 60px;
    background-position: -595px -75px;
}

.lb-L0003 {
    width: 33px;
    height: 60px;
    background-position: -617px -145px;
}

.lb-L0004 {
    width: 33px;
    height: 60px;
    background-position: -617px -215px;
}

.lb-L0005 {
    width: 33px;
    height: 60px;
    background-position: -625px -285px;
}

.lb-L0006 {
    width: 33px;
    height: 60px;
    background-position: -625px -355px;
}

.lb-L0007 {
    width: 33px;
    height: 60px;
    background-position: -621px -425px;
}

.lb-I0000 {
    width: 55px;
    height: 60px;
    background-position: -61px -585px;
}

.lb-I0001 {
    width: 55px;
    height: 60px;
    background-position: -126px -588px;
}

.lb-I0002 {
    width: 55px;
    height: 60px;
    background-position: -191px -588px;
}

.lb-I0003 {
    width: 55px;
    height: 60px;
    background-position: -256px -588px;
}

.lb-I0004 {
    width: 55px;
    height: 60px;
    background-position: -321px -588px;
}

.lb-I0005 {
    width: 55px;
    height: 60px;
    background-position: -386px -588px;
}

.lb-I0006 {
    width: 55px;
    height: 60px;
    background-position: -451px -588px;
}

.lb-I0007 {
    width: 55px;
    height: 60px;
    background-position: -516px -586px;
}

.lb-J0000 {
    width: 33px;
    height: 60px;
    background-position: -621px -495px;
}

.lb-J0001 {
    width: 33px;
    height: 60px;
    background-position: -621px -565px;
}

.lb-J0002 {
    width: 33px;
    height: 60px;
    background-position: -662px -5px;
}

.lb-J0003 {
    width: 33px;
    height: 60px;
    background-position: -638px -75px;
}

.lb-J0004 {
    width: 33px;
    height: 60px;
    background-position: -660px -145px;
}

.lb-J0005 {
    width: 33px;
    height: 60px;
    background-position: -660px -215px;
}

.lb-J0006 {
    width: 33px;
    height: 60px;
    background-position: -668px -285px;
}

.lb-J0007 {
    width: 33px;
    height: 60px;
    background-position: -668px -355px;
}



//RED

.red {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Red.webp');
    background-repeat: no-repeat;
    display: block;
}

.red-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -5px;
}

.red-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.red-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.red-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.red-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.red-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.red-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.red-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.red-B0000 {
    width: 65px;
    height: 60px;
    background-position: -479px -5px;
}

.red-B0001 {
    width: 65px;
    height: 60px;
    background-position: -554px -5px;
}

.red-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -75px;
}

.red-B0003 {
    width: 65px;
    height: 60px;
    background-position: -90px -75px;
}

.red-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.red-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.red-B0006 {
    width: 65px;
    height: 60px;
    background-position: -315px -75px;
}

.red-B0007 {
    width: 65px;
    height: 60px;
    background-position: -390px -75px;
}

.red-C0000 {
    width: 66px;
    height: 60px;
    background-position: -455px -75px;
}

.red-C0001 {
    width: 66px;
    height: 60px;
    background-position: -531px -75px;
}

.red-C0002 {
    width: 66px;
    height: 60px;
    background-position: -5px -145px;
}

.red-C0003 {
    width: 66px;
    height: 60px;
    background-position: -81px -145px;
}

.red-C0004 {
    width: 66px;
    height: 60px;
    background-position: -157px -145px;
}

.red-C0005 {
    width: 66px;
    height: 60px;
    background-position: -233px -145px;
}

.red-C0006 {
    width: 66px;
    height: 60px;
    background-position: -309px -145px;
}

.red-C0007 {
    width: 66px;
    height: 60px;
    background-position: -385px -145px;
}

.red-D0000 {
    width: 60px;
    height: 47px;
    background-position: -466px -145px;
}

.red-D0001 {
    width: 60px;
    height: 47px;
    background-position: -536px -145px;
}

.red-D0002 {
    width: 60px;
    height: 47px;
    background-position: -466px -202px;
}

.red-D0003 {
    width: 60px;
    height: 47px;
    background-position: -536px -202px;
}

.red-D0004 {
    width: 60px;
    height: 47px;
    background-position: -10px -259px;
}

.red-D0005 {
    width: 60px;
    height: 47px;
    background-position: -80px -259px;
}

.red-D0006 {
    width: 60px;
    height: 47px;
    background-position: -150px -259px;
}

.red-D0007 {
    width: 60px;
    height: 47px;
    background-position: -220px -259px;
}

.red-E0000 {
    width: 42px;
    height: 60px;
    background-position: -285px -259px;
}

.red-E0001 {
    width: 42px;
    height: 60px;
    background-position: -337px -259px;
}

.red-E0002 {
    width: 42px;
    height: 60px;
    background-position: -389px -259px;
}

.red-E0003 {
    width: 42px;
    height: 60px;
    background-position: -441px -259px;
}

.red-E0004 {
    width: 42px;
    height: 60px;
    background-position: -493px -259px;
}

.red-E0005 {
    width: 42px;
    height: 60px;
    background-position: -545px -259px;
}

.red-E0006 {
    width: 42px;
    height: 60px;
    background-position: -5px -329px;
}

.red-E0007 {
    width: 42px;
    height: 60px;
    background-position: -57px -329px;
}

.red-F0000 {
    width: 63px;
    height: 60px;
    background-position: -119px -329px;
}

.red-F0001 {
    width: 63px;
    height: 60px;
    background-position: -192px -329px;
}

.red-F0002 {
    width: 63px;
    height: 60px;
    background-position: -265px -329px;
}

.red-F0003 {
    width: 63px;
    height: 60px;
    background-position: -338px -329px;
}

.red-F0004 {
    width: 63px;
    height: 60px;
    background-position: -411px -329px;
}

.red-F0005 {
    width: 63px;
    height: 60px;
    background-position: -484px -329px;
}

.red-F0006 {
    width: 63px;
    height: 60px;
    background-position: -557px -329px;
}

.red-F0007 {
    width: 63px;
    height: 60px;
    background-position: -15px -399px;
}

.red-G0000 {
    width: 58px;
    height: 60px;
    background-position: -78px -399px;
}

.red-G0001 {
    width: 58px;
    height: 60px;
    background-position: -146px -399px;
}

.red-G0002 {
    width: 58px;
    height: 60px;
    background-position: -214px -399px;
}

.red-G0003 {
    width: 58px;
    height: 60px;
    background-position: -282px -399px;
}

.red-G0004 {
    width: 58px;
    height: 60px;
    background-position: -350px -399px;
}

.red-G0005 {
    width: 58px;
    height: 60px;
    background-position: -418px -399px;
}

.red-G0006 {
    width: 58px;
    height: 60px;
    background-position: -486px -399px;
}

.red-G0007 {
    width: 58px;
    height: 60px;
    background-position: -554px -399px;
}

.red-H0000 {
    width: 46px;
    height: 60px;
    background-position: -5px -469px;
}

.red-H0001 {
    width: 46px;
    height: 60px;
    background-position: -61px -469px;
}

.red-H0002 {
    width: 46px;
    height: 60px;
    background-position: -117px -469px;
}

.red-H0003 {
    width: 46px;
    height: 60px;
    background-position: -173px -469px;
}

.red-H0004 {
    width: 46px;
    height: 60px;
    background-position: -229px -469px;
}

.red-H0005 {
    width: 46px;
    height: 60px;
    background-position: -285px -469px;
}

.red-H0006 {
    width: 46px;
    height: 60px;
    background-position: -341px -469px;
}

.red-H0007 {
    width: 46px;
    height: 60px;
    background-position: -397px -469px;
}

.red-K0000 {
    width: 60px;
    height: 25px;
    background-position: -453px -469px;
}

.red-K0001 {
    width: 60px;
    height: 25px;
    background-position: -523px -469px;
}

.red-K0002 {
    width: 60px;
    height: 25px;
    background-position: -453px -504px;
}

.red-K0003 {
    width: 60px;
    height: 25px;
    background-position: -523px -504px;
}

.red-K0004 {
    width: 60px;
    height: 25px;
    background-position: -5px -539px;
}

.red-K0005 {
    width: 60px;
    height: 25px;
    background-position: -75px -539px;
}

.red-K0006 {
    width: 60px;
    height: 25px;
    background-position: -145px -539px;
}

.red-K0007 {
    width: 60px;
    height: 25px;
    background-position: -215px -539px;
}

.red-L0000 {
    width: 33px;
    height: 60px;
    background-position: -285px -539px;
}

.red-L0001 {
    width: 33px;
    height: 60px;
    background-position: -328px -539px;
}

.red-L0002 {
    width: 33px;
    height: 60px;
    background-position: -371px -539px;
}

.red-L0003 {
    width: 33px;
    height: 60px;
    background-position: -414px -539px;
}

.red-L0004 {
    width: 33px;
    height: 60px;
    background-position: -457px -539px;
}

.red-L0005 {
    width: 33px;
    height: 60px;
    background-position: -500px -539px;
}

.red-L0006 {
    width: 33px;
    height: 60px;
    background-position: -543px -539px;
}

.red-L0007 {
    width: 33px;
    height: 60px;
    background-position: -586px -539px;
}

.red-I0000 {
    width: 55px;
    height: 60px;
    background-position: -619px -5px;
}

.red-I0001 {
    width: 55px;
    height: 60px;
    background-position: -607px -75px;
}

.red-I0002 {
    width: 55px;
    height: 60px;
    background-position: -601px -145px;
}

.red-I0003 {
    width: 55px;
    height: 60px;
    background-position: -601px -215px;
}

.red-I0004 {
    width: 55px;
    height: 60px;
    background-position: -620px -285px;
}

.red-I0005 {
    width: 55px;
    height: 60px;
    background-position: -622px -355px;
}

.red-I0006 {
    width: 55px;
    height: 60px;
    background-position: -622px -425px;
}

.red-I0007 {
    width: 55px;
    height: 60px;
    background-position: -629px -495px;
}

.red-J0000 {
    width: 33px;
    height: 60px;
    background-position: -629px -565px;
}

.red-J0001 {
    width: 33px;
    height: 60px;
    background-position: -684px -5px;
}

.red-J0002 {
    width: 33px;
    height: 60px;
    background-position: -672px -75px;
}

.red-J0003 {
    width: 33px;
    height: 60px;
    background-position: -666px -145px;
}

.red-J0004 {
    width: 33px;
    height: 60px;
    background-position: -666px -215px;
}

.red-J0005 {
    width: 33px;
    height: 60px;
    background-position: -685px -285px;
}

.red-J0006 {
    width: 33px;
    height: 60px;
    background-position: -687px -355px;
}

.red-J0007 {
    width: 33px;
    height: 60px;
    background-position: -687px -425px;
}






// Yellow

.yellow {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Yellow.webp');
    background-repeat: no-repeat;
    display: block;
}

.yellow-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -5px;
}

.yellow-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.yellow-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.yellow-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.yellow-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.yellow-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.yellow-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.yellow-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.yellow-B0000 {
    width: 65px;
    height: 60px;
    background-position: -479px -5px;
}

.yellow-B0001 {
    width: 65px;
    height: 60px;
    background-position: -554px -5px;
}

.yellow-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -75px;
}

.yellow-B0003 {
    width: 65px;
    height: 60px;
    background-position: -90px -75px;
}

.yellow-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.yellow-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.yellow-B0006 {
    width: 65px;
    height: 60px;
    background-position: -315px -75px;
}

.yellow-B0007 {
    width: 65px;
    height: 60px;
    background-position: -390px -75px;
}

.yellow-C0000 {
    width: 66px;
    height: 60px;
    background-position: -455px -75px;
}

.yellow-C0001 {
    width: 66px;
    height: 60px;
    background-position: -531px -75px;
}

.yellow-C0002 {
    width: 66px;
    height: 60px;
    background-position: -5px -145px;
}

.yellow-C0003 {
    width: 66px;
    height: 60px;
    background-position: -81px -145px;
}

.yellow-C0004 {
    width: 66px;
    height: 60px;
    background-position: -157px -145px;
}

.yellow-C0005 {
    width: 66px;
    height: 60px;
    background-position: -233px -145px;
}

.yellow-C0006 {
    width: 66px;
    height: 60px;
    background-position: -309px -145px;
}

.yellow-C0007 {
    width: 66px;
    height: 60px;
    background-position: -385px -145px;
}

.yellow-D0000 {
    width: 60px;
    height: 47px;
    background-position: -466px -145px;
}

.yellow-D0001 {
    width: 60px;
    height: 47px;
    background-position: -536px -145px;
}

.yellow-D0002 {
    width: 60px;
    height: 47px;
    background-position: -466px -202px;
}

.yellow-D0003 {
    width: 60px;
    height: 47px;
    background-position: -536px -202px;
}

.yellow-D0004 {
    width: 60px;
    height: 47px;
    background-position: -10px -259px;
}

.yellow-D0005 {
    width: 60px;
    height: 47px;
    background-position: -80px -259px;
}

.yellow-D0006 {
    width: 60px;
    height: 47px;
    background-position: -150px -259px;
}

.yellow-D0007 {
    width: 60px;
    height: 47px;
    background-position: -220px -259px;
}

.yellow-E0000 {
    width: 42px;
    height: 60px;
    background-position: -285px -259px;
}

.yellow-E0001 {
    width: 42px;
    height: 60px;
    background-position: -337px -259px;
}

.yellow-E0002 {
    width: 42px;
    height: 60px;
    background-position: -389px -259px;
}

.yellow-E0003 {
    width: 42px;
    height: 60px;
    background-position: -441px -259px;
}

.yellow-E0004 {
    width: 42px;
    height: 60px;
    background-position: -493px -259px;
}

.yellow-E0005 {
    width: 42px;
    height: 60px;
    background-position: -545px -259px;
}

.yellow-E0006 {
    width: 42px;
    height: 60px;
    background-position: -5px -329px;
}

.yellow-E0007 {
    width: 42px;
    height: 60px;
    background-position: -57px -329px;
}

.yellow-F0000 {
    width: 63px;
    height: 60px;
    background-position: -109px -329px;
}

.yellow-F0001 {
    width: 63px;
    height: 60px;
    background-position: -182px -329px;
}

.yellow-F0002 {
    width: 63px;
    height: 60px;
    background-position: -255px -329px;
}

.yellow-F0003 {
    width: 63px;
    height: 60px;
    background-position: -328px -329px;
}

.yellow-F0004 {
    width: 63px;
    height: 60px;
    background-position: -401px -329px;
}

.yellow-F0005 {
    width: 63px;
    height: 60px;
    background-position: -474px -329px;
}

.yellow-F0006 {
    width: 63px;
    height: 60px;
    background-position: -547px -329px;
}

.yellow-F0007 {
    width: 63px;
    height: 60px;
    background-position: -5px -399px;
}

.yellow-G0000 {
    width: 58px;
    height: 60px;
    background-position: -78px -399px;
}

.yellow-G0001 {
    width: 58px;
    height: 60px;
    background-position: -146px -399px;
}

.yellow-G0002 {
    width: 58px;
    height: 60px;
    background-position: -214px -399px;
}

.yellow-G0003 {
    width: 58px;
    height: 60px;
    background-position: -282px -399px;
}

.yellow-G0004 {
    width: 58px;
    height: 60px;
    background-position: -350px -399px;
}

.yellow-G0005 {
    width: 58px;
    height: 60px;
    background-position: -418px -399px;
}

.yellow-G0006 {
    width: 58px;
    height: 60px;
    background-position: -486px -399px;
}

.yellow-G0007 {
    width: 58px;
    height: 60px;
    background-position: -554px -399px;
}

.yellow-H0000 {
    width: 46px;
    height: 60px;
    background-position: -5px -469px;
}

.yellow-H0001 {
    width: 46px;
    height: 60px;
    background-position: -61px -469px;
}

.yellow-H0002 {
    width: 46px;
    height: 60px;
    background-position: -117px -469px;
}

.yellow-H0003 {
    width: 46px;
    height: 60px;
    background-position: -173px -469px;
}

.yellow-H0004 {
    width: 46px;
    height: 60px;
    background-position: -229px -469px;
}

.yellow-H0005 {
    width: 46px;
    height: 60px;
    background-position: -285px -469px;
}

.yellow-H0006 {
    width: 46px;
    height: 60px;
    background-position: -341px -469px;
}

.yellow-H0007 {
    width: 46px;
    height: 60px;
    background-position: -397px -469px;
}

.yellow-K0000 {
    width: 60px;
    height: 25px;
    background-position: -453px -469px;
}

.yellow-K0001 {
    width: 60px;
    height: 25px;
    background-position: -523px -469px;
}

.yellow-K0002 {
    width: 60px;
    height: 25px;
    background-position: -453px -504px;
}

.yellow-K0003 {
    width: 60px;
    height: 25px;
    background-position: -523px -504px;
}

.yellow-K0004 {
    width: 60px;
    height: 25px;
    background-position: -5px -539px;
}

.yellow-K0005 {
    width: 60px;
    height: 25px;
    background-position: -75px -539px;
}

.yellow-K0006 {
    width: 60px;
    height: 25px;
    background-position: -145px -539px;
}

.yellow-K0007 {
    width: 60px;
    height: 25px;
    background-position: -215px -539px;
}

.yellow-L0000 {
    width: 33px;
    height: 60px;
    background-position: -285px -539px;
}

.yellow-L0001 {
    width: 33px;
    height: 60px;
    background-position: -328px -539px;
}

.yellow-L0002 {
    width: 33px;
    height: 60px;
    background-position: -371px -539px;
}

.yellow-L0003 {
    width: 33px;
    height: 60px;
    background-position: -414px -539px;
}

.yellow-L0004 {
    width: 33px;
    height: 60px;
    background-position: -457px -539px;
}

.yellow-L0005 {
    width: 33px;
    height: 60px;
    background-position: -500px -539px;
}

.yellow-L0006 {
    width: 33px;
    height: 60px;
    background-position: -543px -539px;
}

.yellow-L0007 {
    width: 33px;
    height: 60px;
    background-position: -586px -539px;
}

.yellow-I0000 {
    width: 55px;
    height: 60px;
    background-position: -619px -5px;
}

.yellow-I0001 {
    width: 55px;
    height: 60px;
    background-position: -607px -75px;
}

.yellow-I0002 {
    width: 55px;
    height: 60px;
    background-position: -601px -145px;
}

.yellow-I0003 {
    width: 55px;
    height: 60px;
    background-position: -601px -215px;
}

.yellow-I0004 {
    width: 55px;
    height: 60px;
    background-position: -620px -285px;
}

.yellow-I0005 {
    width: 55px;
    height: 60px;
    background-position: -622px -355px;
}

.yellow-I0006 {
    width: 55px;
    height: 60px;
    background-position: -622px -425px;
}

.yellow-I0007 {
    width: 55px;
    height: 60px;
    background-position: -629px -495px;
}

.yellow-J0000 {
    width: 33px;
    height: 60px;
    background-position: -629px -565px;
}

.yellow-J0001 {
    width: 33px;
    height: 60px;
    background-position: -684px -5px;
}

.yellow-J0002 {
    width: 33px;
    height: 60px;
    background-position: -672px -75px;
}

.yellow-J0003 {
    width: 33px;
    height: 60px;
    background-position: -666px -145px;
}

.yellow-J0004 {
    width: 33px;
    height: 60px;
    background-position: -666px -215px;
}

.yellow-J0005 {
    width: 33px;
    height: 60px;
    background-position: -685px -285px;
}

.yellow-J0006 {
    width: 33px;
    height: 60px;
    background-position: -687px -355px;
}

.yellow-J0007 {
    width: 33px;
    height: 60px;
    background-position: -687px -425px;
}






// Green

.green {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Green.webp');
    background-repeat: no-repeat;
    display: block;
}

.green-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -5px;
}

.green-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.green-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.green-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.green-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.green-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.green-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.green-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.green-B0000 {
    width: 65px;
    height: 60px;
    background-position: -479px -5px;
}

.green-B0001 {
    width: 65px;
    height: 60px;
    background-position: -554px -5px;
}

.green-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -75px;
}

.green-B0003 {
    width: 65px;
    height: 60px;
    background-position: -90px -75px;
}

.green-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.green-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.green-B0006 {
    width: 65px;
    height: 60px;
    background-position: -315px -75px;
}

.green-B0007 {
    width: 65px;
    height: 60px;
    background-position: -390px -75px;
}

.green-C0000 {
    width: 66px;
    height: 60px;
    background-position: -455px -75px;
}

.green-C0001 {
    width: 66px;
    height: 60px;
    background-position: -531px -75px;
}

.green-C0002 {
    width: 66px;
    height: 60px;
    background-position: -5px -145px;
}

.green-C0003 {
    width: 66px;
    height: 60px;
    background-position: -81px -145px;
}

.green-C0004 {
    width: 66px;
    height: 60px;
    background-position: -157px -145px;
}

.green-C0005 {
    width: 66px;
    height: 60px;
    background-position: -233px -145px;
}

.green-C0006 {
    width: 66px;
    height: 60px;
    background-position: -309px -145px;
}

.green-C0007 {
    width: 66px;
    height: 60px;
    background-position: -385px -145px;
}

.green-D0000 {
    width: 60px;
    height: 47px;
    background-position: -466px -145px;
}

.green-D0001 {
    width: 60px;
    height: 47px;
    background-position: -536px -145px;
}

.green-D0002 {
    width: 60px;
    height: 47px;
    background-position: -466px -202px;
}

.green-D0003 {
    width: 60px;
    height: 47px;
    background-position: -536px -202px;
}

.green-D0004 {
    width: 60px;
    height: 47px;
    background-position: -10px -259px;
}

.green-D0005 {
    width: 60px;
    height: 47px;
    background-position: -80px -259px;
}

.green-D0006 {
    width: 60px;
    height: 47px;
    background-position: -150px -259px;
}

.green-D0007 {
    width: 60px;
    height: 47px;
    background-position: -220px -259px;
}

.green-E0000 {
    width: 42px;
    height: 60px;
    background-position: -285px -259px;
}

.green-E0001 {
    width: 42px;
    height: 60px;
    background-position: -337px -259px;
}

.green-E0002 {
    width: 42px;
    height: 60px;
    background-position: -389px -259px;
}

.green-E0003 {
    width: 42px;
    height: 60px;
    background-position: -441px -259px;
}

.green-E0004 {
    width: 42px;
    height: 60px;
    background-position: -493px -259px;
}

.green-E0005 {
    width: 42px;
    height: 60px;
    background-position: -545px -259px;
}

.green-E0006 {
    width: 42px;
    height: 60px;
    background-position: -5px -329px;
}

.green-E0007 {
    width: 42px;
    height: 60px;
    background-position: -57px -329px;
}

.green-F0000 {
    width: 63px;
    height: 60px;
    background-position: -119px -329px;
}

.green-F0001 {
    width: 63px;
    height: 60px;
    background-position: -192px -329px;
}

.green-F0002 {
    width: 63px;
    height: 60px;
    background-position: -265px -329px;
}

.green-F0003 {
    width: 63px;
    height: 60px;
    background-position: -338px -329px;
}

.green-F0004 {
    width: 63px;
    height: 60px;
    background-position: -411px -329px;
}

.green-F0005 {
    width: 63px;
    height: 60px;
    background-position: -484px -329px;
}

.green-F0006 {
    width: 63px;
    height: 60px;
    background-position: -557px -329px;
}

.green-F0007 {
    width: 63px;
    height: 60px;
    background-position: -15px -399px;
}

.green-G0000 {
    width: 58px;
    height: 60px;
    background-position: -78px -399px;
}

.green-G0001 {
    width: 58px;
    height: 60px;
    background-position: -146px -399px;
}

.green-G0002 {
    width: 58px;
    height: 60px;
    background-position: -214px -399px;
}

.green-G0003 {
    width: 58px;
    height: 60px;
    background-position: -282px -399px;
}

.green-G0004 {
    width: 58px;
    height: 60px;
    background-position: -350px -399px;
}

.green-G0005 {
    width: 58px;
    height: 60px;
    background-position: -418px -399px;
}

.green-G0006 {
    width: 58px;
    height: 60px;
    background-position: -486px -399px;
}

.green-G0007 {
    width: 58px;
    height: 60px;
    background-position: -554px -399px;
}

.green-H0000 {
    width: 46px;
    height: 60px;
    background-position: -5px -469px;
}

.green-H0001 {
    width: 46px;
    height: 60px;
    background-position: -61px -469px;
}

.green-H0002 {
    width: 46px;
    height: 60px;
    background-position: -117px -469px;
}

.green-H0003 {
    width: 46px;
    height: 60px;
    background-position: -173px -469px;
}

.green-H0004 {
    width: 46px;
    height: 60px;
    background-position: -229px -469px;
}

.green-H0005 {
    width: 46px;
    height: 60px;
    background-position: -285px -469px;
}

.green-H0006 {
    width: 46px;
    height: 60px;
    background-position: -341px -469px;
}

.green-H0007 {
    width: 46px;
    height: 60px;
    background-position: -397px -469px;
}

.green-K0000 {
    width: 60px;
    height: 25px;
    background-position: -453px -469px;
}

.green-K0001 {
    width: 60px;
    height: 25px;
    background-position: -523px -469px;
}

.green-K0002 {
    width: 60px;
    height: 25px;
    background-position: -453px -504px;
}

.green-K0003 {
    width: 60px;
    height: 25px;
    background-position: -523px -504px;
}

.green-K0004 {
    width: 60px;
    height: 25px;
    background-position: -5px -539px;
}

.green-K0005 {
    width: 60px;
    height: 25px;
    background-position: -75px -539px;
}

.green-K0006 {
    width: 60px;
    height: 25px;
    background-position: -145px -539px;
}

.green-K0007 {
    width: 60px;
    height: 25px;
    background-position: -215px -539px;
}

.green-L0000 {
    width: 33px;
    height: 60px;
    background-position: -285px -539px;
}

.green-L0001 {
    width: 33px;
    height: 60px;
    background-position: -328px -539px;
}

.green-L0002 {
    width: 33px;
    height: 60px;
    background-position: -371px -539px;
}

.green-L0003 {
    width: 33px;
    height: 60px;
    background-position: -414px -539px;
}

.green-L0004 {
    width: 33px;
    height: 60px;
    background-position: -457px -539px;
}

.green-L0005 {
    width: 33px;
    height: 60px;
    background-position: -500px -539px;
}

.green-L0006 {
    width: 33px;
    height: 60px;
    background-position: -543px -539px;
}

.green-L0007 {
    width: 33px;
    height: 60px;
    background-position: -586px -539px;
}

.green-I0000 {
    width: 55px;
    height: 60px;
    background-position: -619px -5px;
}

.green-I0001 {
    width: 55px;
    height: 60px;
    background-position: -607px -75px;
}

.green-I0002 {
    width: 55px;
    height: 60px;
    background-position: -601px -145px;
}

.green-I0003 {
    width: 55px;
    height: 60px;
    background-position: -601px -215px;
}

.green-I0004 {
    width: 55px;
    height: 60px;
    background-position: -620px -285px;
}

.green-I0005 {
    width: 55px;
    height: 60px;
    background-position: -622px -355px;
}

.green-I0006 {
    width: 55px;
    height: 60px;
    background-position: -622px -425px;
}

.green-I0007 {
    width: 55px;
    height: 60px;
    background-position: -629px -495px;
}

.green-J0000 {
    width: 33px;
    height: 60px;
    background-position: -629px -565px;
}

.green-J0001 {
    width: 33px;
    height: 60px;
    background-position: -684px -5px;
}

.green-J0002 {
    width: 33px;
    height: 60px;
    background-position: -672px -75px;
}

.green-J0003 {
    width: 33px;
    height: 60px;
    background-position: -666px -145px;
}

.green-J0004 {
    width: 33px;
    height: 60px;
    background-position: -666px -215px;
}

.green-J0005 {
    width: 33px;
    height: 60px;
    background-position: -685px -285px;
}

.green-J0006 {
    width: 33px;
    height: 60px;
    background-position: -687px -355px;
}

.green-J0007 {
    width: 33px;
    height: 60px;
    background-position: -687px -425px;
}





// White

.white {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/White.webp');
    background-repeat: no-repeat;
    display: block;
}
.white-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -5px;
}

.white-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.white-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.white-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.white-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.white-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.white-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.white-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.white-B0000 {
    width: 65px;
    height: 60px;
    background-position: -479px -5px;
}

.white-B0001 {
    width: 65px;
    height: 60px;
    background-position: -554px -5px;
}

.white-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -75px;
}

.white-B0003 {
    width: 65px;
    height: 60px;
    background-position: -90px -75px;
}

.white-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.white-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.white-B0006 {
    width: 65px;
    height: 60px;
    background-position: -315px -75px;
}

.white-B0007 {
    width: 65px;
    height: 60px;
    background-position: -390px -75px;
}

.white-C0000 {
    width: 66px;
    height: 60px;
    background-position: -455px -75px;
}

.white-C0001 {
    width: 66px;
    height: 60px;
    background-position: -531px -75px;
}

.white-C0002 {
    width: 66px;
    height: 60px;
    background-position: -5px -145px;
}

.white-C0003 {
    width: 66px;
    height: 60px;
    background-position: -81px -145px;
}

.white-C0004 {
    width: 66px;
    height: 60px;
    background-position: -157px -145px;
}

.white-C0005 {
    width: 66px;
    height: 60px;
    background-position: -233px -145px;
}

.white-C0006 {
    width: 66px;
    height: 60px;
    background-position: -309px -145px;
}

.white-C0007 {
    width: 66px;
    height: 60px;
    background-position: -385px -145px;
}

.white-D0000 {
    width: 60px;
    height: 47px;
    background-position: -461px -145px;
}

.white-D0001 {
    width: 60px;
    height: 47px;
    background-position: -531px -145px;
}

.white-D0002 {
    width: 60px;
    height: 47px;
    background-position: -461px -202px;
}

.white-D0003 {
    width: 60px;
    height: 47px;
    background-position: -531px -202px;
}

.white-D0004 {
    width: 60px;
    height: 47px;
    background-position: -5px -259px;
}

.white-D0005 {
    width: 60px;
    height: 47px;
    background-position: -75px -259px;
}

.white-D0006 {
    width: 60px;
    height: 47px;
    background-position: -145px -259px;
}

.white-D0007 {
    width: 60px;
    height: 47px;
    background-position: -215px -259px;
}

.white-E0000 {
    width: 42px;
    height: 60px;
    background-position: -285px -259px;
}

.white-E0001 {
    width: 42px;
    height: 60px;
    background-position: -337px -259px;
}

.white-E0002 {
    width: 42px;
    height: 60px;
    background-position: -389px -259px;
}

.white-E0003 {
    width: 42px;
    height: 60px;
    background-position: -441px -259px;
}

.white-E0004 {
    width: 42px;
    height: 60px;
    background-position: -493px -259px;
}

.white-E0005 {
    width: 42px;
    height: 60px;
    background-position: -545px -259px;
}

.white-E0006 {
    width: 42px;
    height: 60px;
    background-position: -5px -329px;
}

.white-E0007 {
    width: 42px;
    height: 60px;
    background-position: -57px -329px;
}

.white-F0000 {
    width: 63px;
    height: 60px;
    background-position: -109px -329px;
}

.white-F0001 {
    width: 63px;
    height: 60px;
    background-position: -182px -329px;
}

.white-F0002 {
    width: 63px;
    height: 60px;
    background-position: -255px -329px;
}

.white-F0003 {
    width: 63px;
    height: 60px;
    background-position: -328px -329px;
}

.white-F0004 {
    width: 63px;
    height: 60px;
    background-position: -401px -329px;
}

.white-F0005 {
    width: 63px;
    height: 60px;
    background-position: -474px -329px;
}

.white-F0006 {
    width: 63px;
    height: 60px;
    background-position: -547px -329px;
}

.white-F0007 {
    width: 63px;
    height: 60px;
    background-position: -5px -399px;
}

.white-G0000 {
    width: 58px;
    height: 60px;
    background-position: -78px -399px;
}

.white-G0001 {
    width: 58px;
    height: 60px;
    background-position: -146px -399px;
}

.white-G0002 {
    width: 58px;
    height: 60px;
    background-position: -214px -399px;
}

.white-G0003 {
    width: 58px;
    height: 60px;
    background-position: -282px -399px;
}

.white-G0004 {
    width: 58px;
    height: 60px;
    background-position: -350px -399px;
}

.white-G0005 {
    width: 58px;
    height: 60px;
    background-position: -418px -399px;
}

.white-G0006 {
    width: 58px;
    height: 60px;
    background-position: -486px -399px;
}

.white-G0007 {
    width: 58px;
    height: 60px;
    background-position: -554px -399px;
}

.white-H0000 {
    width: 46px;
    height: 60px;
    background-position: -5px -469px;
}

.white-H0001 {
    width: 46px;
    height: 60px;
    background-position: -61px -469px;
}

.white-H0002 {
    width: 46px;
    height: 60px;
    background-position: -117px -469px;
}

.white-H0003 {
    width: 46px;
    height: 60px;
    background-position: -173px -469px;
}

.white-H0004 {
    width: 46px;
    height: 60px;
    background-position: -229px -469px;
}

.white-H0005 {
    width: 46px;
    height: 60px;
    background-position: -285px -469px;
}

.white-H0006 {
    width: 46px;
    height: 60px;
    background-position: -341px -469px;
}

.white-H0007 {
    width: 46px;
    height: 60px;
    background-position: -397px -469px;
}

.white-K0000 {
    width: 60px;
    height: 25px;
    background-position: -453px -469px;
}

.white-K0001 {
    width: 60px;
    height: 25px;
    background-position: -523px -469px;
}

.white-K0002 {
    width: 60px;
    height: 25px;
    background-position: -453px -504px;
}

.white-K0003 {
    width: 60px;
    height: 25px;
    background-position: -523px -504px;
}

.white-K0004 {
    width: 60px;
    height: 25px;
    background-position: -5px -539px;
}

.white-K0005 {
    width: 60px;
    height: 25px;
    background-position: -75px -539px;
}

.white-K0006 {
    width: 60px;
    height: 25px;
    background-position: -145px -539px;
}

.white-K0007 {
    width: 60px;
    height: 25px;
    background-position: -215px -539px;
}

.white-L0000 {
    width: 33px;
    height: 60px;
    background-position: -285px -539px;
}

.white-L0001 {
    width: 33px;
    height: 60px;
    background-position: -328px -539px;
}

.white-L0002 {
    width: 33px;
    height: 60px;
    background-position: -371px -539px;
}

.white-L0003 {
    width: 33px;
    height: 60px;
    background-position: -414px -539px;
}

.white-L0004 {
    width: 33px;
    height: 60px;
    background-position: -457px -539px;
}

.white-L0005 {
    width: 33px;
    height: 60px;
    background-position: -500px -539px;
}

.white-L0006 {
    width: 33px;
    height: 60px;
    background-position: -543px -539px;
}

.white-L0007 {
    width: 33px;
    height: 60px;
    background-position: -586px -539px;
}

.white-I0000 {
    width: 55px;
    height: 60px;
    background-position: -619px -5px;
}

.white-I0001 {
    width: 55px;
    height: 60px;
    background-position: -607px -75px;
}

.white-I0002 {
    width: 55px;
    height: 60px;
    background-position: -601px -145px;
}

.white-I0003 {
    width: 55px;
    height: 60px;
    background-position: -601px -215px;
}

.white-I0004 {
    width: 55px;
    height: 60px;
    background-position: -620px -285px;
}

.white-I0005 {
    width: 55px;
    height: 60px;
    background-position: -622px -355px;
}

.white-I0006 {
    width: 55px;
    height: 60px;
    background-position: -622px -425px;
}

.white-I0007 {
    width: 55px;
    height: 60px;
    background-position: -629px -495px;
}

.white-J0000 {
    width: 33px;
    height: 60px;
    background-position: -629px -565px;
}

.white-J0001 {
    width: 33px;
    height: 60px;
    background-position: -684px -5px;
}

.white-J0002 {
    width: 33px;
    height: 60px;
    background-position: -672px -75px;
}

.white-J0003 {
    width: 33px;
    height: 60px;
    background-position: -666px -145px;
}

.white-J0004 {
    width: 33px;
    height: 60px;
    background-position: -666px -215px;
}

.white-J0005 {
    width: 33px;
    height: 60px;
    background-position: -685px -285px;
}

.white-J0006 {
    width: 33px;
    height: 60px;
    background-position: -687px -355px;
}

.white-J0007 {
    width: 33px;
    height: 60px;
    background-position: -687px -425px;
}

.sprites-element{
    transform: scale(.8);
    cursor: pointer;
}


// DarkBlue

.db {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/DarkBlue.webp');
    background-repeat: no-repeat;
    display: block;
}
.db-A0000 {
    width: 48px;
    height: 60px;
    background-position: -5px -5px;
}

.db-A0001 {
    width: 48px;
    height: 60px;
    background-position: -63px -5px;
}

.db-A0002 {
    width: 48px;
    height: 60px;
    background-position: -121px -5px;
}

.db-A0003 {
    width: 48px;
    height: 60px;
    background-position: -179px -5px;
}

.db-A0004 {
    width: 48px;
    height: 60px;
    background-position: -237px -5px;
}

.db-A0005 {
    width: 48px;
    height: 60px;
    background-position: -295px -5px;
}

.db-A0006 {
    width: 48px;
    height: 60px;
    background-position: -353px -5px;
}

.db-A0007 {
    width: 48px;
    height: 60px;
    background-position: -411px -5px;
}

.db-B0000 {
    width: 65px;
    height: 60px;
    background-position: -479px -5px;
}

.db-B0001 {
    width: 65px;
    height: 60px;
    background-position: -554px -5px;
}

.db-B0002 {
    width: 65px;
    height: 60px;
    background-position: -15px -75px;
}

.db-B0003 {
    width: 65px;
    height: 60px;
    background-position: -90px -75px;
}

.db-B0004 {
    width: 65px;
    height: 60px;
    background-position: -165px -75px;
}

.db-B0005 {
    width: 65px;
    height: 60px;
    background-position: -240px -75px;
}

.db-B0006 {
    width: 65px;
    height: 60px;
    background-position: -315px -75px;
}

.db-B0007 {
    width: 65px;
    height: 60px;
    background-position: -390px -75px;
}

.db-C0000 {
    width: 66px;
    height: 60px;
    background-position: -455px -75px;
}

.db-C0001 {
    width: 66px;
    height: 60px;
    background-position: -531px -75px;
}

.db-C0002 {
    width: 66px;
    height: 60px;
    background-position: -5px -145px;
}

.db-C0003 {
    width: 66px;
    height: 60px;
    background-position: -81px -145px;
}

.db-C0004 {
    width: 66px;
    height: 60px;
    background-position: -157px -145px;
}

.db-C0005 {
    width: 66px;
    height: 60px;
    background-position: -233px -145px;
}

.db-C0006 {
    width: 66px;
    height: 60px;
    background-position: -309px -145px;
}

.db-C0007 {
    width: 66px;
    height: 60px;
    background-position: -385px -145px;
}

.db-D0000 {
    width: 60px;
    height: 47px;
    background-position: -466px -145px;
}

.db-D0001 {
    width: 60px;
    height: 47px;
    background-position: -536px -145px;
}

.db-D0002 {
    width: 60px;
    height: 47px;
    background-position: -466px -202px;
}

.db-D0003 {
    width: 60px;
    height: 47px;
    background-position: -536px -202px;
}

.db-D0004 {
    width: 60px;
    height: 47px;
    background-position: -10px -259px;
}

.db-D0005 {
    width: 60px;
    height: 47px;
    background-position: -80px -259px;
}

.db-D0006 {
    width: 60px;
    height: 47px;
    background-position: -150px -259px;
}

.db-D0007 {
    width: 60px;
    height: 47px;
    background-position: -220px -259px;
}

.db-E0000 {
    width: 42px;
    height: 60px;
    background-position: -285px -259px;
}

.db-E0001 {
    width: 42px;
    height: 60px;
    background-position: -337px -259px;
}

.db-E0002 {
    width: 42px;
    height: 60px;
    background-position: -389px -259px;
}

.db-E0003 {
    width: 42px;
    height: 60px;
    background-position: -441px -259px;
}

.db-E0004 {
    width: 42px;
    height: 60px;
    background-position: -493px -259px;
}

.db-E0005 {
    width: 42px;
    height: 60px;
    background-position: -545px -259px;
}

.db-E0006 {
    width: 42px;
    height: 60px;
    background-position: -5px -329px;
}

.db-E0007 {
    width: 42px;
    height: 60px;
    background-position: -57px -329px;
}

.db-F0000 {
    width: 63px;
    height: 60px;
    background-position: -119px -329px;
}

.db-F0001 {
    width: 63px;
    height: 60px;
    background-position: -192px -329px;
}

.db-F0002 {
    width: 63px;
    height: 60px;
    background-position: -265px -329px;
}

.db-F0003 {
    width: 63px;
    height: 60px;
    background-position: -338px -329px;
}

.db-F0004 {
    width: 63px;
    height: 60px;
    background-position: -411px -329px;
}

.db-F0005 {
    width: 63px;
    height: 60px;
    background-position: -484px -329px;
}

.db-F0006 {
    width: 63px;
    height: 60px;
    background-position: -557px -329px;
}

.db-F0007 {
    width: 63px;
    height: 60px;
    background-position: -15px -399px;
}

.db-G0000 {
    width: 58px;
    height: 60px;
    background-position: -78px -399px;
}

.db-G0001 {
    width: 58px;
    height: 60px;
    background-position: -146px -399px;
}

.db-G0002 {
    width: 58px;
    height: 60px;
    background-position: -214px -399px;
}

.db-G0003 {
    width: 58px;
    height: 60px;
    background-position: -282px -399px;
}

.db-G0004 {
    width: 58px;
    height: 60px;
    background-position: -350px -399px;
}

.db-G0005 {
    width: 58px;
    height: 60px;
    background-position: -418px -399px;
}

.db-G0006 {
    width: 58px;
    height: 60px;
    background-position: -486px -399px;
}

.db-G0007 {
    width: 58px;
    height: 60px;
    background-position: -554px -399px;
}

.db-H0000 {
    width: 46px;
    height: 60px;
    background-position: -5px -469px;
}

.db-H0001 {
    width: 46px;
    height: 60px;
    background-position: -61px -469px;
}

.db-H0002 {
    width: 46px;
    height: 60px;
    background-position: -117px -469px;
}

.db-H0003 {
    width: 46px;
    height: 60px;
    background-position: -173px -469px;
}

.db-H0004 {
    width: 46px;
    height: 60px;
    background-position: -229px -469px;
}

.db-H0005 {
    width: 46px;
    height: 60px;
    background-position: -285px -469px;
}

.db-H0006 {
    width: 46px;
    height: 60px;
    background-position: -341px -469px;
}

.db-H0007 {
    width: 46px;
    height: 60px;
    background-position: -397px -469px;
}

.db-K0000 {
    width: 60px;
    height: 25px;
    background-position: -453px -469px;
}

.db-K0001 {
    width: 60px;
    height: 25px;
    background-position: -523px -469px;
}

.db-K0002 {
    width: 60px;
    height: 25px;
    background-position: -453px -504px;
}

.db-K0003 {
    width: 60px;
    height: 25px;
    background-position: -523px -504px;
}

.db-K0004 {
    width: 60px;
    height: 25px;
    background-position: -5px -539px;
}

.db-K0005 {
    width: 60px;
    height: 25px;
    background-position: -75px -539px;
}

.db-K0006 {
    width: 60px;
    height: 25px;
    background-position: -145px -539px;
}

.db-K0007 {
    width: 60px;
    height: 25px;
    background-position: -215px -539px;
}

.db-L0000 {
    width: 33px;
    height: 60px;
    background-position: -285px -539px;
}

.db-L0001 {
    width: 33px;
    height: 60px;
    background-position: -328px -539px;
}

.db-L0002 {
    width: 33px;
    height: 60px;
    background-position: -371px -539px;
}

.db-L0003 {
    width: 33px;
    height: 60px;
    background-position: -414px -539px;
}

.db-L0004 {
    width: 33px;
    height: 60px;
    background-position: -457px -539px;
}

.db-L0005 {
    width: 33px;
    height: 60px;
    background-position: -500px -539px;
}

.db-L0006 {
    width: 33px;
    height: 60px;
    background-position: -543px -539px;
}

.db-L0007 {
    width: 33px;
    height: 60px;
    background-position: -586px -539px;
}

.db-I0000 {
    width: 55px;
    height: 60px;
    background-position: -619px -5px;
}

.db-I0001 {
    width: 55px;
    height: 60px;
    background-position: -607px -75px;
}

.db-I0002 {
    width: 55px;
    height: 60px;
    background-position: -601px -145px;
}

.db-I0003 {
    width: 55px;
    height: 60px;
    background-position: -601px -215px;
}

.db-I0004 {
    width: 55px;
    height: 60px;
    background-position: -620px -285px;
}

.db-I0005 {
    width: 55px;
    height: 60px;
    background-position: -622px -355px;
}

.db-I0006 {
    width: 55px;
    height: 60px;
    background-position: -622px -425px;
}

.db-I0007 {
    width: 55px;
    height: 60px;
    background-position: -629px -495px;
}

.db-J0000 {
    width: 33px;
    height: 60px;
    background-position: -629px -565px;
}

.db-J0001 {
    width: 33px;
    height: 60px;
    background-position: -684px -5px;
}

.db-J0002 {
    width: 33px;
    height: 60px;
    background-position: -672px -75px;
}

.db-J0003 {
    width: 33px;
    height: 60px;
    background-position: -666px -145px;
}

.db-J0004 {
    width: 33px;
    height: 60px;
    background-position: -666px -215px;
}

.db-J0005 {
    width: 33px;
    height: 60px;
    background-position: -685px -285px;
}

.db-J0006 {
    width: 33px;
    height: 60px;
    background-position: -687px -355px;
}

.db-J0007 {
    width: 33px;
    height: 60px;
    background-position: -687px -425px;
}





// Goali

.goali {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/Goali.webp');
    background-repeat: no-repeat;
    display: block;
}
.goali-A0000 {
    width: 39px;
    height: 60px;
    background-position: -5px -5px;
}

.goali-A0001 {
    width: 39px;
    height: 60px;
    background-position: -54px -5px;
}

.goali-A0002 {
    width: 39px;
    height: 60px;
    background-position: -103px -5px;
}

.goali-A0003 {
    width: 39px;
    height: 60px;
    background-position: -152px -5px;
}

.goali-A0004 {
    width: 39px;
    height: 60px;
    background-position: -201px -5px;
}

.goali-A0005 {
    width: 39px;
    height: 60px;
    background-position: -250px -5px;
}

.goali-A0006 {
    width: 39px;
    height: 60px;
    background-position: -299px -5px;
}

.goali-A0007 {
    width: 39px;
    height: 60px;
    background-position: -348px -5px;
}

.goali-A0008 {
    width: 39px;
    height: 60px;
    background-position: -397px -5px;
}

.goali-B0000 {
    width: 47px;
    height: 60px;
    background-position: -446px -5px;
}

.goali-B0001 {
    width: 47px;
    height: 60px;
    background-position: -503px -5px;
}

.goali-B0002 {
    width: 47px;
    height: 60px;
    background-position: -560px -5px;
}

.goali-B0003 {
    width: 47px;
    height: 60px;
    background-position: -617px -5px;
}

.goali-B0004 {
    width: 47px;
    height: 60px;
    background-position: -5px -75px;
}

.goali-B0005 {
    width: 47px;
    height: 60px;
    background-position: -62px -75px;
}

.goali-B0006 {
    width: 47px;
    height: 60px;
    background-position: -119px -75px;
}

.goali-B0007 {
    width: 47px;
    height: 60px;
    background-position: -176px -75px;
}

.goali-C0000 {
    width: 56px;
    height: 60px;
    background-position: -233px -75px;
}

.goali-C0001 {
    width: 56px;
    height: 60px;
    background-position: -299px -75px;
}

.goali-C0002 {
    width: 56px;
    height: 60px;
    background-position: -365px -75px;
}

.goali-C0003 {
    width: 56px;
    height: 60px;
    background-position: -431px -75px;
}

.goali-C0004 {
    width: 56px;
    height: 60px;
    background-position: -497px -75px;
}

.goali-C0005 {
    width: 56px;
    height: 60px;
    background-position: -563px -75px;
}

.goali-C0006 {
    width: 56px;
    height: 60px;
    background-position: -629px -75px;
}

.goali-C0007 {
    width: 56px;
    height: 60px;
    background-position: -5px -145px;
}

.goali-D0000 {
    width: 60px;
    height: 46px;
    background-position: -77px -145px;
}

.goali-D0001 {
    width: 60px;
    height: 46px;
    background-position: -147px -145px;
}

.goali-D0002 {
    width: 60px;
    height: 46px;
    background-position: -217px -145px;
}

.goali-D0003 {
    width: 60px;
    height: 46px;
    background-position: -287px -145px;
}

.goali-D0004 {
    width: 60px;
    height: 46px;
    background-position: -357px -145px;
}

.goali-D0005 {
    width: 60px;
    height: 46px;
    background-position: -427px -145px;
}

.goali-D0006 {
    width: 60px;
    height: 46px;
    background-position: -497px -145px;
}

.goali-D0007 {
    width: 60px;
    height: 46px;
    background-position: -567px -145px;
}

.goali-E0000 {
    width: 39px;
    height: 60px;
    background-position: -631px -145px;
}

.goali-E0001 {
    width: 39px;
    height: 60px;
    background-position: -5px -215px;
}

.goali-E0002 {
    width: 39px;
    height: 60px;
    background-position: -54px -215px;
}

.goali-E0003 {
    width: 39px;
    height: 60px;
    background-position: -103px -215px;
}

.goali-E0004 {
    width: 39px;
    height: 60px;
    background-position: -152px -215px;
}

.goali-E0005 {
    width: 39px;
    height: 60px;
    background-position: -201px -215px;
}

.goali-E0006 {
    width: 39px;
    height: 60px;
    background-position: -250px -215px;
}

.goali-E0007 {
    width: 39px;
    height: 60px;
    background-position: -299px -215px;
}

.goali-F0000 {
    width: 39px;
    height: 60px;
    background-position: -348px -215px;
}

.goali-F0001 {
    width: 39px;
    height: 60px;
    background-position: -397px -215px;
}

.goali-F0002 {
    width: 39px;
    height: 60px;
    background-position: -446px -215px;
}

.goali-F0003 {
    width: 39px;
    height: 60px;
    background-position: -495px -215px;
}

.goali-F0004 {
    width: 39px;
    height: 60px;
    background-position: -544px -215px;
}

.goali-F0005 {
    width: 39px;
    height: 60px;
    background-position: -593px -215px;
}

.goali-F0006 {
    width: 39px;
    height: 60px;
    background-position: -642px -215px;
}

.goali-F0007 {
    width: 39px;
    height: 60px;
    background-position: -5px -285px;
}

.goali-G0000 {
    width: 34px;
    height: 60px;
    background-position: -54px -285px;
}

.goali-G0001 {
    width: 34px;
    height: 60px;
    background-position: -98px -285px;
}

.goali-G0002 {
    width: 34px;
    height: 60px;
    background-position: -142px -285px;
}

.goali-G0003 {
    width: 34px;
    height: 60px;
    background-position: -186px -285px;
}

.goali-G0004 {
    width: 34px;
    height: 60px;
    background-position: -230px -285px;
}

.goali-G0005 {
    width: 34px;
    height: 60px;
    background-position: -274px -285px;
}

.goali-G0006 {
    width: 34px;
    height: 60px;
    background-position: -318px -285px;
}

.goali-G0007 {
    width: 34px;
    height: 60px;
    background-position: -362px -285px;
}

.goali-H0000 {
    width: 60px;
    height: 43px;
    background-position: -416px -285px;
}

.goali-H0001 {
    width: 60px;
    height: 43px;
    background-position: -486px -285px;
}

.goali-H0002 {
    width: 60px;
    height: 43px;
    background-position: -556px -285px;
}

.goali-H0003 {
    width: 60px;
    height: 43px;
    background-position: -626px -285px;
}

.goali-H0004 {
    width: 60px;
    height: 43px;
    background-position: -416px -338px;
}

.goali-H0005 {
    width: 60px;
    height: 43px;
    background-position: -486px -338px;
}

.goali-H0006 {
    width: 60px;
    height: 43px;
    background-position: -556px -338px;
}

.goali-H0007 {
    width: 60px;
    height: 43px;
    background-position: -626px -338px;
}

.goali-I0000 {
    width: 60px;
    height: 45px;
    background-position: -11px -391px;
}

.goali-I0001 {
    width: 60px;
    height: 45px;
    background-position: -81px -391px;
}

.goali-I0002 {
    width: 60px;
    height: 45px;
    background-position: -151px -391px;
}

.goali-I0003 {
    width: 60px;
    height: 45px;
    background-position: -221px -391px;
}

.goali-I0004 {
    width: 60px;
    height: 45px;
    background-position: -291px -391px;
}

.goali-I0005 {
    width: 60px;
    height: 45px;
    background-position: -361px -391px;
}

.goali-I0006 {
    width: 60px;
    height: 45px;
    background-position: -431px -391px;
}

.goali-I0007 {
    width: 60px;
    height: 45px;
    background-position: -501px -391px;
}

.goali-J0000 {
    width: 60px;
    height: 28px;
    background-position: -565px -391px;
}

.goali-J0001 {
    width: 60px;
    height: 28px;
    background-position: -635px -391px;
}

.goali-J0002 {
    width: 60px;
    height: 28px;
    background-position: -565px -429px;
}

.goali-J0003 {
    width: 60px;
    height: 28px;
    background-position: -635px -429px;
}

.goali-J0004 {
    width: 60px;
    height: 28px;
    background-position: -5px -467px;
}

.goali-J0005 {
    width: 60px;
    height: 28px;
    background-position: -75px -467px;
}

.goali-J0006 {
    width: 60px;
    height: 28px;
    background-position: -145px -467px;
}

.goali-J0007 {
    width: 60px;
    height: 28px;
    background-position: -215px -467px;
}

.goali-K0000 {
    width: 44px;
    height: 60px;
    background-position: -285px -467px;
}

.goali-K0001 {
    width: 44px;
    height: 60px;
    background-position: -339px -467px;
}

.goali-K0002 {
    width: 44px;
    height: 60px;
    background-position: -393px -467px;
}

.goali-K0003 {
    width: 44px;
    height: 60px;
    background-position: -447px -467px;
}

.goali-K0004 {
    width: 44px;
    height: 60px;
    background-position: -501px -467px;
}

.goali-K0005 {
    width: 44px;
    height: 60px;
    background-position: -555px -467px;
}

.goali-K0006 {
    width: 44px;
    height: 60px;
    background-position: -609px -467px;
}

.goali-K0007 {
    width: 44px;
    height: 60px;
    background-position: -5px -537px;
}

.goali-L0000 {
    width: 44px;
    height: 60px;
    background-position: -59px -537px;
}

.goali-L0001 {
    width: 44px;
    height: 60px;
    background-position: -113px -537px;
}

.goali-L0002 {
    width: 44px;
    height: 60px;
    background-position: -167px -537px;
}

.goali-L0003 {
    width: 44px;
    height: 60px;
    background-position: -221px -537px;
}

.goali-L0004 {
    width: 44px;
    height: 60px;
    background-position: -275px -537px;
}

.goali-L0005 {
    width: 44px;
    height: 60px;
    background-position: -329px -537px;
}

.goali-L0006 {
    width: 44px;
    height: 60px;
    background-position: -383px -537px;
}

.goali-L0007 {
    width: 44px;
    height: 60px;
    background-position: -437px -537px;
}

.goali-M0000 {
    width: 59px;
    height: 60px;
    background-position: -491px -537px;
}

.goali-M0001 {
    width: 59px;
    height: 60px;
    background-position: -560px -537px;
}

.goali-M0002 {
    width: 59px;
    height: 60px;
    background-position: -629px -537px;
}

.goali-M0003 {
    width: 59px;
    height: 60px;
    background-position: -5px -607px;
}

.goali-M0004 {
    width: 59px;
    height: 60px;
    background-position: -74px -607px;
}

.goali-M0005 {
    width: 59px;
    height: 60px;
    background-position: -143px -607px;
}

.goali-M0006 {
    width: 59px;
    height: 60px;
    background-position: -212px -607px;
}

.goali-M0007 {
    width: 59px;
    height: 60px;
    background-position: -281px -607px;
}

.goali-N0000 {
    width: 60px;
    height: 47px;
    background-position: -350px -607px;
}

.goali-N0001 {
    width: 60px;
    height: 47px;
    background-position: -420px -607px;
}

.goali-N0002 {
    width: 60px;
    height: 47px;
    background-position: -490px -607px;
}

.goali-N0003 {
    width: 60px;
    height: 47px;
    background-position: -560px -607px;
}

.goali-N0004 {
    width: 60px;
    height: 47px;
    background-position: -630px -607px;
}

.goali-N0005 {
    width: 60px;
    height: 47px;
    background-position: -674px -5px;
}

.goali-N0006 {
    width: 60px;
    height: 47px;
    background-position: -695px -62px;
}

.goali-N0007 {
    width: 60px;
    height: 47px;
    background-position: -695px -119px;
}

.goali-O0000 {
    width: 52px;
    height: 60px;
    background-position: -691px -176px;
}

.goali-O0001 {
    width: 52px;
    height: 60px;
    background-position: -691px -246px;
}

.goali-O0002 {
    width: 52px;
    height: 60px;
    background-position: -686px -316px;
}

.goali-O0003 {
    width: 52px;
    height: 60px;
    background-position: -705px -386px;
}

.goali-O0004 {
    width: 52px;
    height: 60px;
    background-position: -705px -456px;
}

.goali-O0005 {
    width: 52px;
    height: 60px;
    background-position: -698px -526px;
}

.goali-O0006 {
    width: 52px;
    height: 60px;
    background-position: -700px -596px;
}

.goali-O0007 {
    width: 52px;
    height: 60px;
    background-position: -350px -666px;
}

.goali-Q0000 {
    width: 51px;
    height: 60px;
    background-position: -412px -666px;
}

.goali-Q0001 {
    width: 51px;
    height: 60px;
    background-position: -473px -666px;
}

.goali-Q0002 {
    width: 51px;
    height: 60px;
    background-position: -534px -666px;
}

.goali-Q0003 {
    width: 51px;
    height: 60px;
    background-position: -595px -666px;
}

.goali-Q0004 {
    width: 51px;
    height: 60px;
    background-position: -656px -666px;
}

.goali-Q0005 {
    width: 51px;
    height: 60px;
    background-position: -765px -5px;
}

.goali-Q0006 {
    width: 51px;
    height: 60px;
    background-position: -765px -75px;
}

.goali-Q0007 {
    width: 51px;
    height: 60px;
    background-position: -765px -145px;
}
// DarkBlue small
.db-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/DarkBlueSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.db-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.db-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.db-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.db-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.db-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.db-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.db-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.db-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.db-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.db-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.db-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.db-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.db-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.db-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.db-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.db-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.db-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.db-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.db-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.db-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.db-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.db-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.db-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.db-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.db-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.db-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.db-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.db-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.db-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.db-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.db-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.db-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.db-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.db-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.db-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.db-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.db-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.db-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.db-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.db-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.db-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.db-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.db-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.db-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.db-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.db-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.db-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.db-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.db-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.db-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.db-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.db-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.db-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.db-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.db-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.db-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.db-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.db-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.db-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.db-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.db-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.db-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.db-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.db-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.db-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.db-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.db-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.db-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.db-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.db-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.db-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.db-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.db-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.db-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.db-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.db-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.db-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.db-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.db-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.db-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.db-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.db-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.db-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.db-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.db-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.db-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.db-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.db-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.db-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.db-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.db-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.db-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.db-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.db-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.db-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.db-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// green small
.green-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GreenSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.green-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.green-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.green-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.green-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.green-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.green-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.green-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.green-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.green-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.green-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.green-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.green-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.green-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.green-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.green-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.green-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.green-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.green-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.green-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.green-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.green-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.green-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.green-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.green-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.green-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.green-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.green-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.green-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.green-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.green-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.green-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.green-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.green-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.green-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.green-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.green-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.green-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.green-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.green-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.green-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.green-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.green-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.green-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.green-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.green-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.green-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.green-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.green-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.green-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.green-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.green-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.green-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.green-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.green-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.green-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.green-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.green-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.green-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.green-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.green-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.green-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.green-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.green-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.green-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.green-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.green-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.green-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.green-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.green-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.green-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.green-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.green-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.green-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.green-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.green-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.green-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.green-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.green-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.green-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.green-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.green-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.green-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.green-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.green-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.green-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.green-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.green-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.green-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.green-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.green-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.green-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.green-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.green-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.green-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.green-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.green-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// lightBlueSmall
.lb-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/LightBlueSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.lb-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.lb-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.lb-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.lb-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.lb-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.lb-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.lb-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.lb-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.lb-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.lb-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.lb-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.lb-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.lb-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.lb-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.lb-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.lb-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.lb-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.lb-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.lb-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.lb-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.lb-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.lb-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.lb-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.lb-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.lb-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.lb-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.lb-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.lb-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.lb-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.lb-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.lb-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.lb-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.lb-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.lb-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.lb-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.lb-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.lb-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.lb-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.lb-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.lb-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.lb-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.lb-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.lb-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.lb-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.lb-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.lb-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.lb-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.lb-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.lb-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.lb-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.lb-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.lb-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.lb-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.lb-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.lb-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.lb-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.lb-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.lb-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.lb-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.lb-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.lb-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.lb-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.lb-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.lb-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.lb-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.lb-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.lb-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.lb-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.lb-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.lb-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.lb-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.lb-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.lb-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.lb-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.lb-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.lb-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.lb-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.lb-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.lb-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.lb-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.lb-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.lb-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.lb-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.lb-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.lb-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.lb-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.lb-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.lb-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.lb-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.lb-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.lb-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.lb-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.lb-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.lb-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.lb-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.lb-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// red-small
.red-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/RedSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.red-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.red-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.red-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.red-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.red-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.red-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.red-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.red-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.red-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.red-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.red-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.red-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.red-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.red-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.red-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.red-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.red-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.red-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.red-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.red-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.red-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.red-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.red-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.red-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.red-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.red-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.red-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.red-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.red-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.red-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.red-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.red-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.red-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.red-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.red-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.red-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.red-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.red-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.red-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.red-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.red-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.red-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.red-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.red-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.red-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.red-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.red-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.red-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.red-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.red-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.red-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.red-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.red-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.red-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.red-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.red-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.red-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.red-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.red-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.red-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.red-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.red-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.red-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.red-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.red-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.red-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.red-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.red-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.red-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.red-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.red-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.red-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.red-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.red-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.red-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.red-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.red-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.red-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.red-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.red-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.red-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.red-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.red-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.red-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.red-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.red-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.red-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.red-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.red-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.red-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.red-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.red-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.red-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.red-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.red-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.red-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// white small
.white-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/WhiteSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.white-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.white-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.white-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.white-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.white-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.white-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.white-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.white-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.white-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.white-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.white-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.white-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.white-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.white-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.white-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.white-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.white-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.white-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.white-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.white-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.white-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.white-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.white-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.white-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.white-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.white-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.white-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.white-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.white-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.white-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.white-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.white-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.white-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.white-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.white-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.white-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.white-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.white-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.white-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.white-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.white-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.white-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.white-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.white-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.white-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.white-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.white-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.white-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.white-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.white-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.white-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.white-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.white-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.white-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.white-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.white-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.white-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.white-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.white-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.white-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.white-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.white-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.white-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.white-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.white-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.white-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.white-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.white-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.white-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.white-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.white-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.white-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.white-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.white-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.white-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.white-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.white-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.white-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.white-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.white-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.white-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.white-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.white-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.white-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.white-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.white-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.white-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.white-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.white-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.white-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.white-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.white-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.white-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.white-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.white-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.white-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// yellow small
.yellow-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/YellowSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.yellow-s-A0000 {
    width: 40px;
    height: 50px;
    background-position: -5px -5px;
}

.yellow-s-A0001 {
    width: 40px;
    height: 50px;
    background-position: -55px -5px;
}

.yellow-s-A0002 {
    width: 40px;
    height: 50px;
    background-position: -105px -5px;
}

.yellow-s-A0003 {
    width: 40px;
    height: 50px;
    background-position: -155px -5px;
}

.yellow-s-A0004 {
    width: 40px;
    height: 50px;
    background-position: -205px -5px;
}

.yellow-s-A0005 {
    width: 40px;
    height: 50px;
    background-position: -255px -5px;
}

.yellow-s-A0006 {
    width: 40px;
    height: 50px;
    background-position: -305px -5px;
}

.yellow-s-A0007 {
    width: 40px;
    height: 50px;
    background-position: -355px -5px;
}

.yellow-s-B0000 {
    width: 54px;
    height: 50px;
    background-position: -405px -5px;
}

.yellow-s-B0001 {
    width: 54px;
    height: 50px;
    background-position: -469px -5px;
}

.yellow-s-B0002 {
    width: 54px;
    height: 50px;
    background-position: -5px -65px;
}

.yellow-s-B0003 {
    width: 54px;
    height: 50px;
    background-position: -69px -65px;
}

.yellow-s-B0004 {
    width: 54px;
    height: 50px;
    background-position: -133px -65px;
}

.yellow-s-B0005 {
    width: 54px;
    height: 50px;
    background-position: -197px -65px;
}

.yellow-s-B0006 {
    width: 54px;
    height: 50px;
    background-position: -261px -65px;
}

.yellow-s-B0007 {
    width: 54px;
    height: 50px;
    background-position: -325px -65px;
}

.yellow-s-C0000 {
    width: 55px;
    height: 50px;
    background-position: -389px -65px;
}

.yellow-s-C0001 {
    width: 55px;
    height: 50px;
    background-position: -454px -65px;
}

.yellow-s-C0002 {
    width: 55px;
    height: 50px;
    background-position: -5px -125px;
}

.yellow-s-C0003 {
    width: 55px;
    height: 50px;
    background-position: -70px -125px;
}

.yellow-s-C0004 {
    width: 55px;
    height: 50px;
    background-position: -135px -125px;
}

.yellow-s-C0005 {
    width: 55px;
    height: 50px;
    background-position: -200px -125px;
}

.yellow-s-C0006 {
    width: 55px;
    height: 50px;
    background-position: -265px -125px;
}

.yellow-s-C0007 {
    width: 55px;
    height: 50px;
    background-position: -330px -125px;
}

.yellow-s-D0000 {
    width: 50px;
    height: 39px;
    background-position: -395px -125px;
}

.yellow-s-D0001 {
    width: 50px;
    height: 39px;
    background-position: -455px -125px;
}

.yellow-s-D0002 {
    width: 50px;
    height: 39px;
    background-position: -395px -174px;
}

.yellow-s-D0003 {
    width: 50px;
    height: 39px;
    background-position: -455px -174px;
}

.yellow-s-D0004 {
    width: 50px;
    height: 39px;
    background-position: -5px -223px;
}

.yellow-s-D0005 {
    width: 50px;
    height: 39px;
    background-position: -65px -223px;
}

.yellow-s-D0006 {
    width: 50px;
    height: 39px;
    background-position: -125px -223px;
}

.yellow-s-D0007 {
    width: 50px;
    height: 39px;
    background-position: -185px -223px;
}

.yellow-s-E0000 {
    width: 35px;
    height: 50px;
    background-position: -245px -223px;
}

.yellow-s-E0001 {
    width: 35px;
    height: 50px;
    background-position: -290px -223px;
}

.yellow-s-E0002 {
    width: 35px;
    height: 50px;
    background-position: -335px -223px;
}

.yellow-s-E0003 {
    width: 35px;
    height: 50px;
    background-position: -380px -223px;
}

.yellow-s-E0004 {
    width: 35px;
    height: 50px;
    background-position: -425px -223px;
}

.yellow-s-E0005 {
    width: 35px;
    height: 50px;
    background-position: -470px -223px;
}

.yellow-s-E0006 {
    width: 35px;
    height: 50px;
    background-position: -5px -283px;
}

.yellow-s-E0007 {
    width: 35px;
    height: 50px;
    background-position: -50px -283px;
}

.yellow-s-F0000 {
    width: 52px;
    height: 50px;
    background-position: -95px -283px;
}

.yellow-s-F0001 {
    width: 52px;
    height: 50px;
    background-position: -157px -283px;
}

.yellow-s-F0002 {
    width: 52px;
    height: 50px;
    background-position: -219px -283px;
}

.yellow-s-F0003 {
    width: 52px;
    height: 50px;
    background-position: -281px -283px;
}

.yellow-s-F0004 {
    width: 52px;
    height: 50px;
    background-position: -343px -283px;
}

.yellow-s-F0005 {
    width: 52px;
    height: 50px;
    background-position: -405px -283px;
}

.yellow-s-F0006 {
    width: 52px;
    height: 50px;
    background-position: -467px -283px;
}

.yellow-s-F0007 {
    width: 52px;
    height: 50px;
    background-position: -5px -343px;
}

.yellow-s-G0000 {
    width: 48px;
    height: 50px;
    background-position: -67px -343px;
}

.yellow-s-G0001 {
    width: 48px;
    height: 50px;
    background-position: -125px -343px;
}

.yellow-s-G0002 {
    width: 48px;
    height: 50px;
    background-position: -183px -343px;
}

.yellow-s-G0003 {
    width: 48px;
    height: 50px;
    background-position: -241px -343px;
}

.yellow-s-G0004 {
    width: 48px;
    height: 50px;
    background-position: -299px -343px;
}

.yellow-s-G0005 {
    width: 48px;
    height: 50px;
    background-position: -357px -343px;
}

.yellow-s-G0006 {
    width: 48px;
    height: 50px;
    background-position: -415px -343px;
}

.yellow-s-G0007 {
    width: 48px;
    height: 50px;
    background-position: -473px -343px;
}

.yellow-s-H0000 {
    width: 38px;
    height: 50px;
    background-position: -5px -403px;
}

.yellow-s-H0001 {
    width: 38px;
    height: 50px;
    background-position: -53px -403px;
}

.yellow-s-H0002 {
    width: 38px;
    height: 50px;
    background-position: -101px -403px;
}

.yellow-s-H0003 {
    width: 38px;
    height: 50px;
    background-position: -149px -403px;
}

.yellow-s-H0004 {
    width: 38px;
    height: 50px;
    background-position: -197px -403px;
}

.yellow-s-H0005 {
    width: 38px;
    height: 50px;
    background-position: -245px -403px;
}

.yellow-s-H0006 {
    width: 38px;
    height: 50px;
    background-position: -293px -403px;
}

.yellow-s-H0007 {
    width: 38px;
    height: 50px;
    background-position: -341px -403px;
}

.yellow-s-I0000 {
    width: 46px;
    height: 50px;
    background-position: -389px -403px;
}

.yellow-s-I0001 {
    width: 46px;
    height: 50px;
    background-position: -445px -403px;
}

.yellow-s-I0002 {
    width: 46px;
    height: 50px;
    background-position: -5px -463px;
}

.yellow-s-I0003 {
    width: 46px;
    height: 50px;
    background-position: -61px -463px;
}

.yellow-s-I0004 {
    width: 46px;
    height: 50px;
    background-position: -117px -463px;
}

.yellow-s-I0005 {
    width: 46px;
    height: 50px;
    background-position: -173px -463px;
}

.yellow-s-I0006 {
    width: 46px;
    height: 50px;
    background-position: -229px -463px;
}

.yellow-s-I0007 {
    width: 46px;
    height: 50px;
    background-position: -285px -463px;
}

.yellow-s-J0000 {
    width: 27px;
    height: 50px;
    background-position: -501px -403px;
}

.yellow-s-J0001 {
    width: 27px;
    height: 50px;
    background-position: -341px -463px;
}

.yellow-s-J0002 {
    width: 27px;
    height: 50px;
    background-position: -378px -463px;
}

.yellow-s-J0003 {
    width: 27px;
    height: 50px;
    background-position: -415px -463px;
}

.yellow-s-J0004 {
    width: 27px;
    height: 50px;
    background-position: -452px -463px;
}

.yellow-s-J0005 {
    width: 27px;
    height: 50px;
    background-position: -489px -463px;
}

.yellow-s-J0006 {
    width: 27px;
    height: 50px;
    background-position: -533px -5px;
}

.yellow-s-J0007 {
    width: 27px;
    height: 50px;
    background-position: -519px -65px;
}

.yellow-s-K0000 {
    width: 50px;
    height: 21px;
    background-position: -515px -125px;
}

.yellow-s-K0001 {
    width: 50px;
    height: 21px;
    background-position: -515px -156px;
}

.yellow-s-K0002 {
    width: 50px;
    height: 21px;
    background-position: -5px -187px;
}

.yellow-s-K0003 {
    width: 50px;
    height: 21px;
    background-position: -65px -187px;
}

.yellow-s-K0004 {
    width: 50px;
    height: 21px;
    background-position: -125px -187px;
}

.yellow-s-K0005 {
    width: 50px;
    height: 21px;
    background-position: -185px -187px;
}

.yellow-s-K0006 {
    width: 50px;
    height: 21px;
    background-position: -245px -187px;
}

.yellow-s-K0007 {
    width: 50px;
    height: 21px;
    background-position: -305px -187px;
}

.yellow-s-L0000 {
    width: 27px;
    height: 50px;
    background-position: -515px -187px;
}

.yellow-s-L0001 {
    width: 27px;
    height: 50px;
    background-position: -529px -247px;
}

.yellow-s-L0002 {
    width: 27px;
    height: 50px;
    background-position: -531px -307px;
}

.yellow-s-L0003 {
    width: 27px;
    height: 50px;
    background-position: -538px -367px;
}

.yellow-s-L0004 {
    width: 27px;
    height: 50px;
    background-position: -538px -427px;
}

.yellow-s-L0005 {
    width: 27px;
    height: 50px;
    background-position: -526px -487px;
}

.yellow-s-L0006 {
    width: 27px;
    height: 50px;
    background-position: -570px -5px;
}

.yellow-s-L0007 {
    width: 27px;
    height: 50px;
    background-position: -556px -65px;
}
// Goali Extra Small

.goali-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GoaliExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.goali-xs-A0000 {
    width: 26px;
    height: 40px;
    background-position: -5px -5px;
}

.goali-xs-A0001 {
    width: 26px;
    height: 40px;
    background-position: -41px -5px;
}

.goali-xs-A0002 {
    width: 26px;
    height: 40px;
    background-position: -77px -5px;
}

.goali-xs-A0003 {
    width: 26px;
    height: 40px;
    background-position: -113px -5px;
}

.goali-xs-A0004 {
    width: 26px;
    height: 40px;
    background-position: -149px -5px;
}

.goali-xs-A0005 {
    width: 26px;
    height: 40px;
    background-position: -185px -5px;
}

.goali-xs-A0006 {
    width: 26px;
    height: 40px;
    background-position: -221px -5px;
}

.goali-xs-A0007 {
    width: 26px;
    height: 40px;
    background-position: -257px -5px;
}

.goali-xs-A0008 {
    width: 26px;
    height: 40px;
    background-position: -293px -5px;
}

.goali-xs-B0000 {
    width: 32px;
    height: 40px;
    background-position: -329px -5px;
}

.goali-xs-B0001 {
    width: 32px;
    height: 40px;
    background-position: -371px -5px;
}

.goali-xs-B0002 {
    width: 32px;
    height: 40px;
    background-position: -413px -5px;
}

.goali-xs-B0003 {
    width: 32px;
    height: 40px;
    background-position: -455px -5px;
}

.goali-xs-B0004 {
    width: 32px;
    height: 40px;
    background-position: -5px -55px;
}

.goali-xs-B0005 {
    width: 32px;
    height: 40px;
    background-position: -47px -55px;
}

.goali-xs-B0006 {
    width: 32px;
    height: 40px;
    background-position: -89px -55px;
}

.goali-xs-B0007 {
    width: 32px;
    height: 40px;
    background-position: -131px -55px;
}

.goali-xs-C0000 {
    width: 38px;
    height: 40px;
    background-position: -173px -55px;
}

.goali-xs-C0001 {
    width: 38px;
    height: 40px;
    background-position: -221px -55px;
}

.goali-xs-C0002 {
    width: 38px;
    height: 40px;
    background-position: -269px -55px;
}

.goali-xs-C0003 {
    width: 38px;
    height: 40px;
    background-position: -317px -55px;
}

.goali-xs-C0004 {
    width: 38px;
    height: 40px;
    background-position: -365px -55px;
}

.goali-xs-C0005 {
    width: 38px;
    height: 40px;
    background-position: -413px -55px;
}

.goali-xs-C0006 {
    width: 38px;
    height: 40px;
    background-position: -461px -55px;
}

.goali-xs-C0007 {
    width: 38px;
    height: 40px;
    background-position: -5px -105px;
}

.goali-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -53px -105px;
}

.goali-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -103px -105px;
}

.goali-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -153px -105px;
}

.goali-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -203px -105px;
}

.goali-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -253px -105px;
}

.goali-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -303px -105px;
}

.goali-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -353px -105px;
}

.goali-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -403px -105px;
}

.goali-xs-E0000 {
    width: 26px;
    height: 40px;
    background-position: -453px -105px;
}

.goali-xs-E0001 {
    width: 26px;
    height: 40px;
    background-position: -5px -155px;
}

.goali-xs-E0002 {
    width: 26px;
    height: 40px;
    background-position: -41px -155px;
}

.goali-xs-E0003 {
    width: 26px;
    height: 40px;
    background-position: -77px -155px;
}

.goali-xs-E0004 {
    width: 26px;
    height: 40px;
    background-position: -113px -155px;
}

.goali-xs-E0005 {
    width: 26px;
    height: 40px;
    background-position: -149px -155px;
}

.goali-xs-E0006 {
    width: 26px;
    height: 40px;
    background-position: -185px -155px;
}

.goali-xs-E0007 {
    width: 26px;
    height: 40px;
    background-position: -221px -155px;
}

.goali-xs-F0000 {
    width: 26px;
    height: 40px;
    background-position: -257px -155px;
}

.goali-xs-F0001 {
    width: 26px;
    height: 40px;
    background-position: -293px -155px;
}

.goali-xs-F0002 {
    width: 26px;
    height: 40px;
    background-position: -329px -155px;
}

.goali-xs-F0003 {
    width: 26px;
    height: 40px;
    background-position: -365px -155px;
}

.goali-xs-F0004 {
    width: 26px;
    height: 40px;
    background-position: -401px -155px;
}

.goali-xs-F0005 {
    width: 26px;
    height: 40px;
    background-position: -437px -155px;
}

.goali-xs-F0006 {
    width: 26px;
    height: 40px;
    background-position: -473px -155px;
}

.goali-xs-F0007 {
    width: 26px;
    height: 40px;
    background-position: -5px -205px;
}

.goali-xs-G0000 {
    width: 22px;
    height: 40px;
    background-position: -41px -205px;
}

.goali-xs-G0001 {
    width: 22px;
    height: 40px;
    background-position: -73px -205px;
}

.goali-xs-G0002 {
    width: 22px;
    height: 40px;
    background-position: -105px -205px;
}

.goali-xs-G0003 {
    width: 22px;
    height: 40px;
    background-position: -137px -205px;
}

.goali-xs-G0004 {
    width: 22px;
    height: 40px;
    background-position: -169px -205px;
}

.goali-xs-G0005 {
    width: 22px;
    height: 40px;
    background-position: -201px -205px;
}

.goali-xs-G0006 {
    width: 22px;
    height: 40px;
    background-position: -233px -205px;
}

.goali-xs-G0007 {
    width: 22px;
    height: 40px;
    background-position: -265px -205px;
}

.goali-xs-H0000 {
    width: 40px;
    height: 29px;
    background-position: -297px -205px;
}

.goali-xs-H0001 {
    width: 40px;
    height: 29px;
    background-position: -347px -205px;
}

.goali-xs-H0002 {
    width: 40px;
    height: 29px;
    background-position: -397px -205px;
}

.goali-xs-H0003 {
    width: 40px;
    height: 29px;
    background-position: -447px -205px;
}

.goali-xs-H0004 {
    width: 40px;
    height: 29px;
    background-position: -297px -244px;
}

.goali-xs-H0005 {
    width: 40px;
    height: 29px;
    background-position: -347px -244px;
}

.goali-xs-H0006 {
    width: 40px;
    height: 29px;
    background-position: -397px -244px;
}

.goali-xs-H0007 {
    width: 40px;
    height: 29px;
    background-position: -447px -244px;
}

.goali-xs-I0000 {
    width: 40px;
    height: 30px;
    background-position: -5px -283px;
}

.goali-xs-I0001 {
    width: 40px;
    height: 30px;
    background-position: -55px -283px;
}

.goali-xs-I0002 {
    width: 40px;
    height: 30px;
    background-position: -105px -283px;
}

.goali-xs-I0003 {
    width: 40px;
    height: 30px;
    background-position: -155px -283px;
}

.goali-xs-I0004 {
    width: 40px;
    height: 30px;
    background-position: -205px -283px;
}

.goali-xs-I0005 {
    width: 40px;
    height: 30px;
    background-position: -255px -283px;
}

.goali-xs-I0006 {
    width: 40px;
    height: 30px;
    background-position: -305px -283px;
}

.goali-xs-I0007 {
    width: 40px;
    height: 30px;
    background-position: -355px -283px;
}

.goali-xs-J0000 {
    width: 40px;
    height: 19px;
    background-position: -405px -283px;
}

.goali-xs-J0001 {
    width: 40px;
    height: 19px;
    background-position: -455px -283px;
}

.goali-xs-J0002 {
    width: 40px;
    height: 19px;
    background-position: -405px -312px;
}

.goali-xs-J0003 {
    width: 40px;
    height: 19px;
    background-position: -455px -312px;
}

.goali-xs-J0004 {
    width: 40px;
    height: 19px;
    background-position: -5px -341px;
}

.goali-xs-J0005 {
    width: 40px;
    height: 19px;
    background-position: -55px -341px;
}

.goali-xs-J0006 {
    width: 40px;
    height: 19px;
    background-position: -105px -341px;
}

.goali-xs-J0007 {
    width: 40px;
    height: 19px;
    background-position: -155px -341px;
}

.goali-xs-K0000 {
    width: 29px;
    height: 40px;
    background-position: -205px -341px;
}

.goali-xs-K0001 {
    width: 29px;
    height: 40px;
    background-position: -244px -341px;
}

.goali-xs-K0002 {
    width: 29px;
    height: 40px;
    background-position: -283px -341px;
}

.goali-xs-K0003 {
    width: 29px;
    height: 40px;
    background-position: -322px -341px;
}

.goali-xs-K0004 {
    width: 29px;
    height: 40px;
    background-position: -361px -341px;
}

.goali-xs-K0005 {
    width: 29px;
    height: 40px;
    background-position: -400px -341px;
}

.goali-xs-K0006 {
    width: 29px;
    height: 40px;
    background-position: -439px -341px;
}

.goali-xs-K0007 {
    width: 29px;
    height: 40px;
    background-position: -5px -391px;
}

.goali-xs-L0000 {
    width: 29px;
    height: 40px;
    background-position: -44px -391px;
}

.goali-xs-L0001 {
    width: 29px;
    height: 40px;
    background-position: -83px -391px;
}

.goali-xs-L0002 {
    width: 29px;
    height: 40px;
    background-position: -122px -391px;
}

.goali-xs-L0003 {
    width: 29px;
    height: 40px;
    background-position: -161px -391px;
}

.goali-xs-L0004 {
    width: 29px;
    height: 40px;
    background-position: -200px -391px;
}

.goali-xs-L0005 {
    width: 29px;
    height: 40px;
    background-position: -239px -391px;
}

.goali-xs-L0006 {
    width: 29px;
    height: 40px;
    background-position: -278px -391px;
}

.goali-xs-L0007 {
    width: 29px;
    height: 40px;
    background-position: -317px -391px;
}

.goali-xs-M0000 {
    width: 40px;
    height: 40px;
    background-position: -356px -391px;
}

.goali-xs-M0001 {
    width: 40px;
    height: 40px;
    background-position: -406px -391px;
}

.goali-xs-M0002 {
    width: 40px;
    height: 40px;
    background-position: -456px -391px;
}

.goali-xs-M0003 {
    width: 40px;
    height: 40px;
    background-position: -5px -441px;
}

.goali-xs-M0004 {
    width: 40px;
    height: 40px;
    background-position: -55px -441px;
}

.goali-xs-M0005 {
    width: 40px;
    height: 40px;
    background-position: -105px -441px;
}

.goali-xs-M0006 {
    width: 40px;
    height: 40px;
    background-position: -155px -441px;
}

.goali-xs-M0007 {
    width: 40px;
    height: 40px;
    background-position: -205px -441px;
}

.goali-xs-N0000 {
    width: 40px;
    height: 32px;
    background-position: -255px -441px;
}

.goali-xs-N0001 {
    width: 40px;
    height: 32px;
    background-position: -305px -441px;
}

.goali-xs-N0002 {
    width: 40px;
    height: 32px;
    background-position: -355px -441px;
}

.goali-xs-N0003 {
    width: 40px;
    height: 32px;
    background-position: -405px -441px;
}

.goali-xs-N0004 {
    width: 40px;
    height: 32px;
    background-position: -455px -441px;
}

.goali-xs-N0005 {
    width: 40px;
    height: 32px;
    background-position: -497px -5px;
}

.goali-xs-N0006 {
    width: 40px;
    height: 32px;
    background-position: -509px -47px;
}

.goali-xs-N0007 {
    width: 40px;
    height: 32px;
    background-position: -509px -89px;
}

.goali-xs-O0000 {
    width: 34px;
    height: 40px;
    background-position: -509px -131px;
}

.goali-xs-O0001 {
    width: 34px;
    height: 40px;
    background-position: -509px -181px;
}

.goali-xs-O0002 {
    width: 34px;
    height: 40px;
    background-position: -497px -231px;
}

.goali-xs-O0003 {
    width: 34px;
    height: 40px;
    background-position: -505px -281px;
}

.goali-xs-O0004 {
    width: 34px;
    height: 40px;
    background-position: -505px -331px;
}

.goali-xs-O0005 {
    width: 34px;
    height: 40px;
    background-position: -506px -381px;
}

.goali-xs-O0006 {
    width: 34px;
    height: 40px;
    background-position: -506px -431px;
}

.goali-xs-O0007 {
    width: 34px;
    height: 40px;
    background-position: -505px -481px;
}

.goali-xs-Q0000 {
    width: 34px;
    height: 40px;
    background-position: -559px -5px;
}

.goali-xs-Q0001 {
    width: 34px;
    height: 40px;
    background-position: -559px -55px;
}

.goali-xs-Q0002 {
    width: 34px;
    height: 40px;
    background-position: -559px -105px;
}

.goali-xs-Q0003 {
    width: 34px;
    height: 40px;
    background-position: -553px -155px;
}

.goali-xs-Q0004 {
    width: 34px;
    height: 40px;
    background-position: -553px -205px;
}

.goali-xs-Q0005 {
    width: 34px;
    height: 40px;
    background-position: -549px -255px;
}

.goali-xs-Q0006 {
    width: 34px;
    height: 40px;
    background-position: -549px -305px;
}

.goali-xs-Q0007 {
    width: 34px;
    height: 40px;
    background-position: -550px -355px;
}

// GoaliSmall
.goali-s {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GoaliSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.goali-s-A0000 {
    width: 32px;
    height: 50px;
    background-position: -5px -5px;
}

.goali-s-A0001 {
    width: 32px;
    height: 50px;
    background-position: -47px -5px;
}

.goali-s-A0002 {
    width: 32px;
    height: 50px;
    background-position: -89px -5px;
}

.goali-s-A0003 {
    width: 32px;
    height: 50px;
    background-position: -131px -5px;
}

.goali-s-A0004 {
    width: 32px;
    height: 50px;
    background-position: -173px -5px;
}

.goali-s-A0005 {
    width: 32px;
    height: 50px;
    background-position: -215px -5px;
}

.goali-s-A0006 {
    width: 32px;
    height: 50px;
    background-position: -257px -5px;
}

.goali-s-A0007 {
    width: 32px;
    height: 50px;
    background-position: -299px -5px;
}

.goali-s-A0008 {
    width: 32px;
    height: 50px;
    background-position: -341px -5px;
}

.goali-s-B0000 {
    width: 39px;
    height: 50px;
    background-position: -383px -5px;
}

.goali-s-B0001 {
    width: 39px;
    height: 50px;
    background-position: -432px -5px;
}

.goali-s-B0002 {
    width: 39px;
    height: 50px;
    background-position: -481px -5px;
}

.goali-s-B0003 {
    width: 39px;
    height: 50px;
    background-position: -530px -5px;
}

.goali-s-B0004 {
    width: 39px;
    height: 50px;
    background-position: -5px -65px;
}

.goali-s-B0005 {
    width: 39px;
    height: 50px;
    background-position: -54px -65px;
}

.goali-s-B0006 {
    width: 39px;
    height: 50px;
    background-position: -103px -65px;
}

.goali-s-B0007 {
    width: 39px;
    height: 50px;
    background-position: -152px -65px;
}

.goali-s-C0000 {
    width: 47px;
    height: 50px;
    background-position: -201px -65px;
}

.goali-s-C0001 {
    width: 47px;
    height: 50px;
    background-position: -258px -65px;
}

.goali-s-C0002 {
    width: 47px;
    height: 50px;
    background-position: -315px -65px;
}

.goali-s-C0003 {
    width: 47px;
    height: 50px;
    background-position: -372px -65px;
}

.goali-s-C0004 {
    width: 47px;
    height: 50px;
    background-position: -429px -65px;
}

.goali-s-C0005 {
    width: 47px;
    height: 50px;
    background-position: -486px -65px;
}

.goali-s-C0006 {
    width: 47px;
    height: 50px;
    background-position: -543px -65px;
}

.goali-s-C0007 {
    width: 47px;
    height: 50px;
    background-position: -5px -125px;
}

.goali-s-D0000 {
    width: 50px;
    height: 38px;
    background-position: -62px -125px;
}

.goali-s-D0001 {
    width: 50px;
    height: 38px;
    background-position: -122px -125px;
}

.goali-s-D0002 {
    width: 50px;
    height: 38px;
    background-position: -182px -125px;
}

.goali-s-D0003 {
    width: 50px;
    height: 38px;
    background-position: -242px -125px;
}

.goali-s-D0004 {
    width: 50px;
    height: 38px;
    background-position: -302px -125px;
}

.goali-s-D0005 {
    width: 50px;
    height: 38px;
    background-position: -362px -125px;
}

.goali-s-D0006 {
    width: 50px;
    height: 38px;
    background-position: -422px -125px;
}

.goali-s-D0007 {
    width: 50px;
    height: 38px;
    background-position: -482px -125px;
}

.goali-s-E0000 {
    width: 32px;
    height: 50px;
    background-position: -542px -125px;
}

.goali-s-E0001 {
    width: 32px;
    height: 50px;
    background-position: -5px -185px;
}

.goali-s-E0002 {
    width: 32px;
    height: 50px;
    background-position: -47px -185px;
}

.goali-s-E0003 {
    width: 32px;
    height: 50px;
    background-position: -89px -185px;
}

.goali-s-E0004 {
    width: 32px;
    height: 50px;
    background-position: -131px -185px;
}

.goali-s-E0005 {
    width: 32px;
    height: 50px;
    background-position: -173px -185px;
}

.goali-s-E0006 {
    width: 32px;
    height: 50px;
    background-position: -215px -185px;
}

.goali-s-E0007 {
    width: 32px;
    height: 50px;
    background-position: -257px -185px;
}

.goali-s-F0000 {
    width: 32px;
    height: 50px;
    background-position: -299px -185px;
}

.goali-s-F0001 {
    width: 32px;
    height: 50px;
    background-position: -341px -185px;
}

.goali-s-F0002 {
    width: 32px;
    height: 50px;
    background-position: -383px -185px;
}

.goali-s-F0003 {
    width: 32px;
    height: 50px;
    background-position: -425px -185px;
}

.goali-s-F0004 {
    width: 32px;
    height: 50px;
    background-position: -467px -185px;
}

.goali-s-F0005 {
    width: 32px;
    height: 50px;
    background-position: -509px -185px;
}

.goali-s-F0006 {
    width: 32px;
    height: 50px;
    background-position: -551px -185px;
}

.goali-s-F0007 {
    width: 32px;
    height: 50px;
    background-position: -5px -245px;
}

.goali-s-G0000 {
    width: 28px;
    height: 50px;
    background-position: -47px -245px;
}

.goali-s-G0001 {
    width: 28px;
    height: 50px;
    background-position: -85px -245px;
}

.goali-s-G0002 {
    width: 28px;
    height: 50px;
    background-position: -123px -245px;
}

.goali-s-G0003 {
    width: 28px;
    height: 50px;
    background-position: -161px -245px;
}

.goali-s-G0004 {
    width: 28px;
    height: 50px;
    background-position: -199px -245px;
}

.goali-s-G0005 {
    width: 28px;
    height: 50px;
    background-position: -237px -245px;
}

.goali-s-G0006 {
    width: 28px;
    height: 50px;
    background-position: -275px -245px;
}

.goali-s-G0007 {
    width: 28px;
    height: 50px;
    background-position: -313px -245px;
}

.goali-s-H0000 {
    width: 50px;
    height: 36px;
    background-position: -351px -245px;
}

.goali-s-H0001 {
    width: 50px;
    height: 36px;
    background-position: -411px -245px;
}

.goali-s-H0002 {
    width: 50px;
    height: 36px;
    background-position: -471px -245px;
}

.goali-s-H0003 {
    width: 50px;
    height: 36px;
    background-position: -531px -245px;
}

.goali-s-H0004 {
    width: 50px;
    height: 36px;
    background-position: -351px -291px;
}

.goali-s-H0005 {
    width: 50px;
    height: 36px;
    background-position: -411px -291px;
}

.goali-s-H0006 {
    width: 50px;
    height: 36px;
    background-position: -471px -291px;
}

.goali-s-H0007 {
    width: 50px;
    height: 36px;
    background-position: -531px -291px;
}

.goali-s-I0000 {
    width: 50px;
    height: 37px;
    background-position: -5px -337px;
}

.goali-s-I0001 {
    width: 50px;
    height: 37px;
    background-position: -65px -337px;
}

.goali-s-I0002 {
    width: 50px;
    height: 37px;
    background-position: -125px -337px;
}

.goali-s-I0003 {
    width: 50px;
    height: 37px;
    background-position: -185px -337px;
}

.goali-s-I0004 {
    width: 50px;
    height: 37px;
    background-position: -245px -337px;
}

.goali-s-I0005 {
    width: 50px;
    height: 37px;
    background-position: -305px -337px;
}

.goali-s-I0006 {
    width: 50px;
    height: 37px;
    background-position: -365px -337px;
}

.goali-s-I0007 {
    width: 50px;
    height: 37px;
    background-position: -425px -337px;
}

.goali-s-J0000 {
    width: 50px;
    height: 24px;
    background-position: -485px -337px;
}

.goali-s-J0001 {
    width: 50px;
    height: 24px;
    background-position: -545px -337px;
}

.goali-s-J0002 {
    width: 50px;
    height: 24px;
    background-position: -485px -371px;
}

.goali-s-J0003 {
    width: 50px;
    height: 24px;
    background-position: -545px -371px;
}

.goali-s-J0004 {
    width: 50px;
    height: 24px;
    background-position: -5px -405px;
}

.goali-s-J0005 {
    width: 50px;
    height: 24px;
    background-position: -65px -405px;
}

.goali-s-J0006 {
    width: 50px;
    height: 24px;
    background-position: -125px -405px;
}

.goali-s-J0007 {
    width: 50px;
    height: 24px;
    background-position: -185px -405px;
}

.goali-s-K0000 {
    width: 36px;
    height: 50px;
    background-position: -245px -405px;
}

.goali-s-K0001 {
    width: 36px;
    height: 50px;
    background-position: -291px -405px;
}

.goali-s-K0002 {
    width: 36px;
    height: 50px;
    background-position: -337px -405px;
}

.goali-s-K0003 {
    width: 36px;
    height: 50px;
    background-position: -383px -405px;
}

.goali-s-K0004 {
    width: 36px;
    height: 50px;
    background-position: -429px -405px;
}

.goali-s-K0005 {
    width: 36px;
    height: 50px;
    background-position: -475px -405px;
}

.goali-s-K0006 {
    width: 36px;
    height: 50px;
    background-position: -521px -405px;
}

.goali-s-K0007 {
    width: 36px;
    height: 50px;
    background-position: -5px -465px;
}

.goali-s-L0000 {
    width: 36px;
    height: 50px;
    background-position: -51px -465px;
}

.goali-s-L0001 {
    width: 36px;
    height: 50px;
    background-position: -97px -465px;
}

.goali-s-L0002 {
    width: 36px;
    height: 50px;
    background-position: -143px -465px;
}

.goali-s-L0003 {
    width: 36px;
    height: 50px;
    background-position: -189px -465px;
}

.goali-s-L0004 {
    width: 36px;
    height: 50px;
    background-position: -235px -465px;
}

.goali-s-L0005 {
    width: 36px;
    height: 50px;
    background-position: -281px -465px;
}

.goali-s-L0006 {
    width: 36px;
    height: 50px;
    background-position: -327px -465px;
}

.goali-s-L0007 {
    width: 36px;
    height: 50px;
    background-position: -373px -465px;
}

.goali-s-M0000 {
    width: 50px;
    height: 50px;
    background-position: -419px -465px;
}

.goali-s-M0001 {
    width: 50px;
    height: 50px;
    background-position: -479px -465px;
}

.goali-s-M0002 {
    width: 50px;
    height: 50px;
    background-position: -539px -465px;
}

.goali-s-M0003 {
    width: 50px;
    height: 50px;
    background-position: -5px -525px;
}

.goali-s-M0004 {
    width: 50px;
    height: 50px;
    background-position: -65px -525px;
}

.goali-s-M0005 {
    width: 50px;
    height: 50px;
    background-position: -125px -525px;
}

.goali-s-M0006 {
    width: 50px;
    height: 50px;
    background-position: -185px -525px;
}

.goali-s-M0007 {
    width: 50px;
    height: 50px;
    background-position: -245px -525px;
}

.goali-s-N0000 {
    width: 50px;
    height: 39px;
    background-position: -305px -525px;
}

.goali-s-N0001 {
    width: 50px;
    height: 39px;
    background-position: -365px -525px;
}

.goali-s-N0002 {
    width: 50px;
    height: 39px;
    background-position: -425px -525px;
}

.goali-s-N0003 {
    width: 50px;
    height: 39px;
    background-position: -485px -525px;
}

.goali-s-N0004 {
    width: 50px;
    height: 39px;
    background-position: -545px -525px;
}

.goali-s-N0005 {
    width: 50px;
    height: 39px;
    background-position: -579px -5px;
}

.goali-s-N0006 {
    width: 50px;
    height: 39px;
    background-position: -600px -54px;
}

.goali-s-N0007 {
    width: 50px;
    height: 39px;
    background-position: -600px -103px;
}

.goali-s-O0000 {
    width: 43px;
    height: 50px;
    background-position: -593px -152px;
}

.goali-s-O0001 {
    width: 43px;
    height: 50px;
    background-position: -593px -212px;
}

.goali-s-O0002 {
    width: 43px;
    height: 50px;
    background-position: -591px -272px;
}

.goali-s-O0003 {
    width: 43px;
    height: 50px;
    background-position: -605px -332px;
}

.goali-s-O0004 {
    width: 43px;
    height: 50px;
    background-position: -605px -392px;
}

.goali-s-O0005 {
    width: 43px;
    height: 50px;
    background-position: -599px -452px;
}

.goali-s-O0006 {
    width: 43px;
    height: 50px;
    background-position: -605px -512px;
}

.goali-s-O0007 {
    width: 43px;
    height: 50px;
    background-position: -605px -572px;
}

.goali-s-Q0000 {
    width: 43px;
    height: 50px;
    background-position: -660px -5px;
}

.goali-s-Q0001 {
    width: 43px;
    height: 50px;
    background-position: -660px -65px;
}

.goali-s-Q0002 {
    width: 43px;
    height: 50px;
    background-position: -660px -125px;
}

.goali-s-Q0003 {
    width: 43px;
    height: 50px;
    background-position: -646px -185px;
}

.goali-s-Q0004 {
    width: 43px;
    height: 50px;
    background-position: -646px -245px;
}

.goali-s-Q0005 {
    width: 43px;
    height: 50px;
    background-position: -658px -305px;
}

.goali-s-Q0006 {
    width: 43px;
    height: 50px;
    background-position: -658px -365px;
}

.goali-s-Q0007 {
    width: 43px;
    height: 50px;
    background-position: -658px -425px;
}

// YellowLarge
.yellow-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/YellowLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.yellow-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.yellow-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.yellow-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.yellow-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.yellow-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.yellow-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.yellow-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.yellow-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.yellow-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.yellow-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.yellow-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.yellow-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.yellow-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.yellow-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.yellow-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.yellow-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.yellow-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.yellow-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.yellow-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.yellow-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.yellow-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.yellow-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.yellow-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.yellow-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.yellow-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.yellow-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.yellow-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.yellow-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.yellow-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.yellow-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.yellow-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.yellow-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.yellow-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.yellow-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.yellow-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.yellow-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.yellow-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.yellow-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.yellow-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.yellow-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.yellow-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.yellow-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.yellow-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.yellow-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.yellow-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.yellow-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.yellow-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.yellow-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.yellow-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.yellow-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.yellow-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.yellow-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.yellow-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.yellow-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.yellow-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.yellow-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.yellow-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.yellow-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.yellow-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.yellow-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.yellow-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.yellow-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.yellow-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.yellow-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.yellow-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.yellow-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.yellow-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.yellow-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.yellow-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.yellow-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.yellow-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.yellow-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.yellow-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.yellow-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.yellow-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.yellow-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.yellow-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.yellow-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.yellow-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.yellow-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.yellow-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.yellow-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.yellow-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.yellow-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.yellow-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.yellow-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.yellow-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.yellow-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.yellow-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.yellow-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.yellow-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.yellow-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.yellow-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.yellow-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.yellow-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.yellow-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}
// GoaliLarge
.goali-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GoaliLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.goali-l-A0000 {
    width: 49px;
    height: 75px;
    background-position: -5px -5px;
}

.goali-l-A0001 {
    width: 49px;
    height: 75px;
    background-position: -64px -5px;
}

.goali-l-A0002 {
    width: 49px;
    height: 75px;
    background-position: -123px -5px;
}

.goali-l-A0003 {
    width: 49px;
    height: 75px;
    background-position: -182px -5px;
}

.goali-l-A0004 {
    width: 49px;
    height: 75px;
    background-position: -241px -5px;
}

.goali-l-A0005 {
    width: 49px;
    height: 75px;
    background-position: -300px -5px;
}

.goali-l-A0006 {
    width: 49px;
    height: 75px;
    background-position: -359px -5px;
}

.goali-l-A0007 {
    width: 49px;
    height: 75px;
    background-position: -418px -5px;
}

.goali-l-A0008 {
    width: 49px;
    height: 75px;
    background-position: -477px -5px;
}

.goali-l-B0000 {
    width: 59px;
    height: 75px;
    background-position: -536px -5px;
}

.goali-l-B0001 {
    width: 59px;
    height: 75px;
    background-position: -605px -5px;
}

.goali-l-B0002 {
    width: 59px;
    height: 75px;
    background-position: -674px -5px;
}

.goali-l-B0003 {
    width: 59px;
    height: 75px;
    background-position: -743px -5px;
}

.goali-l-B0004 {
    width: 59px;
    height: 75px;
    background-position: -5px -90px;
}

.goali-l-B0005 {
    width: 59px;
    height: 75px;
    background-position: -74px -90px;
}

.goali-l-B0006 {
    width: 59px;
    height: 75px;
    background-position: -143px -90px;
}

.goali-l-B0007 {
    width: 59px;
    height: 75px;
    background-position: -212px -90px;
}

.goali-l-C0000 {
    width: 70px;
    height: 75px;
    background-position: -281px -90px;
}

.goali-l-C0001 {
    width: 70px;
    height: 75px;
    background-position: -361px -90px;
}

.goali-l-C0002 {
    width: 70px;
    height: 75px;
    background-position: -441px -90px;
}

.goali-l-C0003 {
    width: 70px;
    height: 75px;
    background-position: -521px -90px;
}

.goali-l-C0004 {
    width: 70px;
    height: 75px;
    background-position: -601px -90px;
}

.goali-l-C0005 {
    width: 70px;
    height: 75px;
    background-position: -681px -90px;
}

.goali-l-C0006 {
    width: 70px;
    height: 75px;
    background-position: -761px -90px;
}

.goali-l-C0007 {
    width: 70px;
    height: 75px;
    background-position: -5px -175px;
}

.goali-l-D0000 {
    width: 75px;
    height: 57px;
    background-position: -85px -175px;
}

.goali-l-D0001 {
    width: 75px;
    height: 57px;
    background-position: -170px -175px;
}

.goali-l-D0002 {
    width: 75px;
    height: 57px;
    background-position: -255px -175px;
}

.goali-l-D0003 {
    width: 75px;
    height: 57px;
    background-position: -340px -175px;
}

.goali-l-D0004 {
    width: 75px;
    height: 57px;
    background-position: -425px -175px;
}

.goali-l-D0005 {
    width: 75px;
    height: 57px;
    background-position: -510px -175px;
}

.goali-l-D0006 {
    width: 75px;
    height: 57px;
    background-position: -595px -175px;
}

.goali-l-D0007 {
    width: 75px;
    height: 57px;
    background-position: -680px -175px;
}

.goali-l-E0000 {
    width: 49px;
    height: 75px;
    background-position: -765px -175px;
}

.goali-l-E0001 {
    width: 49px;
    height: 75px;
    background-position: -5px -260px;
}

.goali-l-E0002 {
    width: 49px;
    height: 75px;
    background-position: -64px -260px;
}

.goali-l-E0003 {
    width: 49px;
    height: 75px;
    background-position: -123px -260px;
}

.goali-l-E0004 {
    width: 49px;
    height: 75px;
    background-position: -182px -260px;
}

.goali-l-E0005 {
    width: 49px;
    height: 75px;
    background-position: -241px -260px;
}

.goali-l-E0006 {
    width: 49px;
    height: 75px;
    background-position: -300px -260px;
}

.goali-l-E0007 {
    width: 49px;
    height: 75px;
    background-position: -359px -260px;
}

.goali-l-F0000 {
    width: 48px;
    height: 75px;
    background-position: -418px -260px;
}

.goali-l-F0001 {
    width: 48px;
    height: 75px;
    background-position: -476px -260px;
}

.goali-l-F0002 {
    width: 48px;
    height: 75px;
    background-position: -534px -260px;
}

.goali-l-F0003 {
    width: 48px;
    height: 75px;
    background-position: -592px -260px;
}

.goali-l-F0004 {
    width: 48px;
    height: 75px;
    background-position: -650px -260px;
}

.goali-l-F0005 {
    width: 48px;
    height: 75px;
    background-position: -708px -260px;
}

.goali-l-F0006 {
    width: 48px;
    height: 75px;
    background-position: -766px -260px;
}

.goali-l-F0007 {
    width: 48px;
    height: 75px;
    background-position: -5px -345px;
}

.goali-l-G0000 {
    width: 42px;
    height: 75px;
    background-position: -63px -345px;
}

.goali-l-G0001 {
    width: 42px;
    height: 75px;
    background-position: -115px -345px;
}

.goali-l-G0002 {
    width: 42px;
    height: 75px;
    background-position: -167px -345px;
}

.goali-l-G0003 {
    width: 42px;
    height: 75px;
    background-position: -219px -345px;
}

.goali-l-G0004 {
    width: 42px;
    height: 75px;
    background-position: -271px -345px;
}

.goali-l-G0005 {
    width: 42px;
    height: 75px;
    background-position: -323px -345px;
}

.goali-l-G0006 {
    width: 42px;
    height: 75px;
    background-position: -375px -345px;
}

.goali-l-G0007 {
    width: 42px;
    height: 75px;
    background-position: -427px -345px;
}

.goali-l-H0000 {
    width: 75px;
    height: 54px;
    background-position: -479px -345px;
}

.goali-l-H0001 {
    width: 75px;
    height: 54px;
    background-position: -564px -345px;
}

.goali-l-H0002 {
    width: 75px;
    height: 54px;
    background-position: -649px -345px;
}

.goali-l-H0003 {
    width: 75px;
    height: 54px;
    background-position: -734px -345px;
}

.goali-l-H0004 {
    width: 75px;
    height: 54px;
    background-position: -479px -409px;
}

.goali-l-H0005 {
    width: 75px;
    height: 54px;
    background-position: -564px -409px;
}

.goali-l-H0006 {
    width: 75px;
    height: 54px;
    background-position: -649px -409px;
}

.goali-l-H0007 {
    width: 75px;
    height: 54px;
    background-position: -734px -409px;
}

.goali-l-I0000 {
    width: 75px;
    height: 56px;
    background-position: -5px -473px;
}

.goali-l-I0001 {
    width: 75px;
    height: 56px;
    background-position: -90px -473px;
}

.goali-l-I0002 {
    width: 75px;
    height: 56px;
    background-position: -175px -473px;
}

.goali-l-I0003 {
    width: 75px;
    height: 56px;
    background-position: -260px -473px;
}

.goali-l-I0004 {
    width: 75px;
    height: 56px;
    background-position: -345px -473px;
}

.goali-l-I0005 {
    width: 75px;
    height: 56px;
    background-position: -430px -473px;
}

.goali-l-I0006 {
    width: 75px;
    height: 56px;
    background-position: -515px -473px;
}

.goali-l-I0007 {
    width: 75px;
    height: 56px;
    background-position: -600px -473px;
}

.goali-l-J0000 {
    width: 75px;
    height: 36px;
    background-position: -685px -473px;
}

.goali-l-J0001 {
    width: 75px;
    height: 36px;
    background-position: -685px -519px;
}

.goali-l-J0002 {
    width: 75px;
    height: 36px;
    background-position: -5px -565px;
}

.goali-l-J0003 {
    width: 75px;
    height: 36px;
    background-position: -90px -565px;
}

.goali-l-J0004 {
    width: 75px;
    height: 36px;
    background-position: -175px -565px;
}

.goali-l-J0005 {
    width: 75px;
    height: 36px;
    background-position: -260px -565px;
}

.goali-l-J0006 {
    width: 75px;
    height: 36px;
    background-position: -345px -565px;
}

.goali-l-J0007 {
    width: 75px;
    height: 36px;
    background-position: -430px -565px;
}

.goali-l-K0000 {
    width: 55px;
    height: 75px;
    background-position: -770px -473px;
}

.goali-l-K0001 {
    width: 55px;
    height: 75px;
    background-position: -515px -558px;
}

.goali-l-K0002 {
    width: 55px;
    height: 75px;
    background-position: -580px -558px;
}

.goali-l-K0003 {
    width: 55px;
    height: 75px;
    background-position: -770px -558px;
}

.goali-l-K0004 {
    width: 55px;
    height: 75px;
    background-position: -5px -643px;
}

.goali-l-K0005 {
    width: 55px;
    height: 75px;
    background-position: -70px -643px;
}

.goali-l-K0006 {
    width: 55px;
    height: 75px;
    background-position: -135px -643px;
}

.goali-l-K0007 {
    width: 55px;
    height: 75px;
    background-position: -200px -643px;
}

.goali-l-L0000 {
    width: 55px;
    height: 75px;
    background-position: -265px -643px;
}

.goali-l-L0001 {
    width: 55px;
    height: 75px;
    background-position: -330px -643px;
}

.goali-l-L0002 {
    width: 55px;
    height: 75px;
    background-position: -395px -643px;
}

.goali-l-L0003 {
    width: 55px;
    height: 75px;
    background-position: -460px -643px;
}

.goali-l-L0004 {
    width: 55px;
    height: 75px;
    background-position: -525px -643px;
}

.goali-l-L0005 {
    width: 55px;
    height: 75px;
    background-position: -590px -643px;
}

.goali-l-L0006 {
    width: 55px;
    height: 75px;
    background-position: -655px -643px;
}

.goali-l-L0007 {
    width: 55px;
    height: 75px;
    background-position: -720px -643px;
}

.goali-l-M0000 {
    width: 74px;
    height: 75px;
    background-position: -5px -728px;
}

.goali-l-M0001 {
    width: 74px;
    height: 75px;
    background-position: -89px -728px;
}

.goali-l-M0002 {
    width: 74px;
    height: 75px;
    background-position: -173px -728px;
}

.goali-l-M0003 {
    width: 74px;
    height: 75px;
    background-position: -257px -728px;
}

.goali-l-M0004 {
    width: 74px;
    height: 75px;
    background-position: -341px -728px;
}

.goali-l-M0005 {
    width: 74px;
    height: 75px;
    background-position: -425px -728px;
}

.goali-l-M0006 {
    width: 74px;
    height: 75px;
    background-position: -509px -728px;
}

.goali-l-M0007 {
    width: 74px;
    height: 75px;
    background-position: -593px -728px;
}

.goali-l-N0000 {
    width: 75px;
    height: 59px;
    background-position: -645px -565px;
}

.goali-l-N0001 {
    width: 75px;
    height: 59px;
    background-position: -677px -728px;
}

.goali-l-N0002 {
    width: 75px;
    height: 59px;
    background-position: -762px -728px;
}

.goali-l-N0003 {
    width: 75px;
    height: 59px;
    background-position: -812px -5px;
}

.goali-l-N0004 {
    width: 75px;
    height: 59px;
    background-position: -841px -74px;
}

.goali-l-N0005 {
    width: 75px;
    height: 59px;
    background-position: -841px -143px;
}

.goali-l-N0006 {
    width: 75px;
    height: 59px;
    background-position: -824px -212px;
}

.goali-l-N0007 {
    width: 75px;
    height: 59px;
    background-position: -824px -281px;
}

.goali-l-O0000 {
    width: 64px;
    height: 75px;
    background-position: -819px -350px;
}

.goali-l-O0001 {
    width: 64px;
    height: 75px;
    background-position: -835px -435px;
}

.goali-l-O0002 {
    width: 64px;
    height: 75px;
    background-position: -835px -520px;
}

.goali-l-O0003 {
    width: 64px;
    height: 75px;
    background-position: -835px -605px;
}

.goali-l-O0004 {
    width: 64px;
    height: 75px;
    background-position: -847px -690px;
}

.goali-l-O0005 {
    width: 64px;
    height: 75px;
    background-position: -847px -775px;
}

.goali-l-O0006 {
    width: 64px;
    height: 75px;
    background-position: -926px -5px;
}

.goali-l-O0007 {
    width: 64px;
    height: 75px;
    background-position: -926px -90px;
}

.goali-l-Q0000 {
    width: 64px;
    height: 75px;
    background-position: -926px -175px;
}

.goali-l-Q0001 {
    width: 64px;
    height: 75px;
    background-position: -909px -260px;
}

.goali-l-Q0002 {
    width: 64px;
    height: 75px;
    background-position: -909px -345px;
}

.goali-l-Q0003 {
    width: 64px;
    height: 75px;
    background-position: -909px -430px;
}

.goali-l-Q0004 {
    width: 64px;
    height: 75px;
    background-position: -909px -515px;
}

.goali-l-Q0005 {
    width: 64px;
    height: 75px;
    background-position: -909px -600px;
}

.goali-l-Q0006 {
    width: 64px;
    height: 75px;
    background-position: -921px -685px;
}

.goali-l-Q0007 {
    width: 64px;
    height: 75px;
    background-position: -921px -770px;
}
// RedLarge
.red-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/RedLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.red-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.red-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.red-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.red-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.red-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.red-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.red-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.red-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.red-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.red-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.red-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.red-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.red-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.red-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.red-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.red-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.red-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.red-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.red-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.red-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.red-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.red-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.red-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.red-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.red-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.red-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.red-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.red-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.red-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.red-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.red-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.red-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.red-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.red-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.red-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.red-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.red-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.red-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.red-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.red-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.red-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.red-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.red-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.red-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.red-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.red-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.red-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.red-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.red-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.red-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.red-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.red-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.red-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.red-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.red-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.red-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.red-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.red-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.red-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.red-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.red-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.red-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.red-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.red-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.red-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.red-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.red-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.red-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.red-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.red-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.red-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.red-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.red-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.red-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.red-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.red-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.red-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.red-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.red-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.red-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.red-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.red-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.red-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.red-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.red-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.red-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.red-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.red-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.red-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.red-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.red-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.red-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.red-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.red-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.red-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.red-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}
// WhiteLarge
.white-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/WhiteLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.white-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.white-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.white-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.white-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.white-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.white-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.white-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.white-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.white-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.white-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.white-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.white-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.white-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.white-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.white-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.white-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.white-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.white-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.white-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.white-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.white-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.white-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.white-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.white-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.white-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.white-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.white-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.white-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.white-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.white-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.white-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.white-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.white-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.white-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.white-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.white-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.white-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.white-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.white-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.white-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.white-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.white-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.white-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.white-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.white-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.white-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.white-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.white-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.white-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.white-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.white-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.white-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.white-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.white-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.white-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.white-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.white-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.white-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.white-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.white-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.white-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.white-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.white-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.white-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.white-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.white-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.white-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.white-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.white-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.white-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.white-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.white-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.white-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.white-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.white-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.white-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.white-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.white-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.white-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.white-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.white-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.white-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.white-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.white-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.white-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.white-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.white-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.white-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.white-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.white-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.white-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.white-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.white-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.white-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.white-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.white-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}
// LightBlueLarge
.lb-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/LightBlueLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.lb-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.lb-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.lb-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.lb-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.lb-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.lb-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.lb-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.lb-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.lb-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.lb-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.lb-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.lb-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.lb-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.lb-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.lb-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.lb-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.lb-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.lb-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.lb-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.lb-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.lb-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.lb-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.lb-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.lb-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.lb-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.lb-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.lb-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.lb-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.lb-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.lb-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.lb-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.lb-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.lb-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.lb-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.lb-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.lb-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.lb-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.lb-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.lb-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.lb-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.lb-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.lb-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.lb-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.lb-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.lb-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.lb-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.lb-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.lb-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.lb-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.lb-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.lb-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.lb-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.lb-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.lb-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.lb-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.lb-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.lb-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.lb-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.lb-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.lb-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.lb-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.lb-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.lb-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.lb-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.lb-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.lb-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.lb-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.lb-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.lb-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.lb-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.lb-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.lb-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.lb-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.lb-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.lb-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.lb-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.lb-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.lb-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.lb-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.lb-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.lb-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.lb-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.lb-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.lb-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.lb-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.lb-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.lb-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.lb-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.lb-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.lb-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.lb-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.lb-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.lb-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.lb-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.lb-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.lb-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}
// GreenLarge
.green-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GreenLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.green-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.green-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.green-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.green-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.green-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.green-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.green-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.green-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.green-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.green-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.green-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.green-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.green-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.green-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.green-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.green-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.green-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.green-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.green-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.green-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.green-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.green-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.green-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.green-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.green-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.green-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.green-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.green-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.green-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.green-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.green-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.green-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.green-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.green-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.green-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.green-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.green-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.green-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.green-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.green-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.green-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.green-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.green-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.green-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.green-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.green-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.green-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.green-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.green-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.green-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.green-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.green-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.green-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.green-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.green-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.green-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.green-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.green-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.green-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.green-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.green-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.green-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.green-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.green-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.green-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.green-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.green-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.green-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.green-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.green-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.green-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.green-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.green-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.green-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.green-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.green-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.green-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.green-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.green-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.green-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.green-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.green-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.green-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.green-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.green-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.green-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.green-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.green-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.green-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.green-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.green-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.green-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.green-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.green-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.green-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.green-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}
// DarkBlueLarge
.db-l {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/DarkBlueLarge.webp');
    background-repeat: no-repeat;
    display: block;
}

.db-l-A0000 {
    width: 61px;
    height: 75px;
    background-position: -5px -5px;
}

.db-l-A0001 {
    width: 61px;
    height: 75px;
    background-position: -76px -5px;
}

.db-l-A0002 {
    width: 61px;
    height: 75px;
    background-position: -147px -5px;
}

.db-l-A0003 {
    width: 61px;
    height: 75px;
    background-position: -218px -5px;
}

.db-l-A0004 {
    width: 61px;
    height: 75px;
    background-position: -289px -5px;
}

.db-l-A0005 {
    width: 61px;
    height: 75px;
    background-position: -360px -5px;
}

.db-l-A0006 {
    width: 61px;
    height: 75px;
    background-position: -431px -5px;
}

.db-l-A0007 {
    width: 61px;
    height: 75px;
    background-position: -502px -5px;
}

.db-l-B0000 {
    width: 81px;
    height: 75px;
    background-position: -573px -5px;
}

.db-l-B0001 {
    width: 81px;
    height: 75px;
    background-position: -664px -5px;
}

.db-l-B0002 {
    width: 81px;
    height: 75px;
    background-position: -5px -90px;
}

.db-l-B0003 {
    width: 81px;
    height: 75px;
    background-position: -96px -90px;
}

.db-l-B0004 {
    width: 81px;
    height: 75px;
    background-position: -187px -90px;
}

.db-l-B0005 {
    width: 81px;
    height: 75px;
    background-position: -278px -90px;
}

.db-l-B0006 {
    width: 81px;
    height: 75px;
    background-position: -369px -90px;
}

.db-l-B0007 {
    width: 81px;
    height: 75px;
    background-position: -460px -90px;
}

.db-l-C0000 {
    width: 83px;
    height: 75px;
    background-position: -551px -90px;
}

.db-l-C0001 {
    width: 83px;
    height: 75px;
    background-position: -644px -90px;
}

.db-l-C0002 {
    width: 83px;
    height: 75px;
    background-position: -5px -175px;
}

.db-l-C0003 {
    width: 83px;
    height: 75px;
    background-position: -98px -175px;
}

.db-l-C0004 {
    width: 83px;
    height: 75px;
    background-position: -191px -175px;
}

.db-l-C0005 {
    width: 83px;
    height: 75px;
    background-position: -284px -175px;
}

.db-l-C0006 {
    width: 83px;
    height: 75px;
    background-position: -377px -175px;
}

.db-l-C0007 {
    width: 83px;
    height: 75px;
    background-position: -470px -175px;
}

.db-l-D0000 {
    width: 75px;
    height: 59px;
    background-position: -563px -175px;
}

.db-l-D0001 {
    width: 75px;
    height: 59px;
    background-position: -648px -175px;
}

.db-l-D0002 {
    width: 75px;
    height: 59px;
    background-position: -563px -244px;
}

.db-l-D0003 {
    width: 75px;
    height: 59px;
    background-position: -648px -244px;
}

.db-l-D0004 {
    width: 75px;
    height: 59px;
    background-position: -5px -313px;
}

.db-l-D0005 {
    width: 75px;
    height: 59px;
    background-position: -90px -313px;
}

.db-l-D0006 {
    width: 75px;
    height: 59px;
    background-position: -175px -313px;
}

.db-l-D0007 {
    width: 75px;
    height: 59px;
    background-position: -260px -313px;
}

.db-l-E0000 {
    width: 53px;
    height: 75px;
    background-position: -345px -313px;
}

.db-l-E0001 {
    width: 53px;
    height: 75px;
    background-position: -408px -313px;
}

.db-l-E0002 {
    width: 53px;
    height: 75px;
    background-position: -471px -313px;
}

.db-l-E0003 {
    width: 53px;
    height: 75px;
    background-position: -534px -313px;
}

.db-l-E0004 {
    width: 53px;
    height: 75px;
    background-position: -597px -313px;
}

.db-l-E0005 {
    width: 53px;
    height: 75px;
    background-position: -660px -313px;
}

.db-l-E0006 {
    width: 53px;
    height: 75px;
    background-position: -5px -398px;
}

.db-l-E0007 {
    width: 53px;
    height: 75px;
    background-position: -68px -398px;
}

.db-l-F0000 {
    width: 78px;
    height: 75px;
    background-position: -131px -398px;
}

.db-l-F0001 {
    width: 78px;
    height: 75px;
    background-position: -219px -398px;
}

.db-l-F0002 {
    width: 78px;
    height: 75px;
    background-position: -307px -398px;
}

.db-l-F0003 {
    width: 78px;
    height: 75px;
    background-position: -395px -398px;
}

.db-l-F0004 {
    width: 78px;
    height: 75px;
    background-position: -483px -398px;
}

.db-l-F0005 {
    width: 78px;
    height: 75px;
    background-position: -571px -398px;
}

.db-l-F0006 {
    width: 78px;
    height: 75px;
    background-position: -659px -398px;
}

.db-l-F0007 {
    width: 78px;
    height: 75px;
    background-position: -5px -483px;
}

.db-l-G0000 {
    width: 72px;
    height: 75px;
    background-position: -93px -483px;
}

.db-l-G0001 {
    width: 72px;
    height: 75px;
    background-position: -175px -483px;
}

.db-l-G0002 {
    width: 72px;
    height: 75px;
    background-position: -257px -483px;
}

.db-l-G0003 {
    width: 72px;
    height: 75px;
    background-position: -339px -483px;
}

.db-l-G0004 {
    width: 72px;
    height: 75px;
    background-position: -421px -483px;
}

.db-l-G0005 {
    width: 72px;
    height: 75px;
    background-position: -503px -483px;
}

.db-l-G0006 {
    width: 72px;
    height: 75px;
    background-position: -585px -483px;
}

.db-l-G0007 {
    width: 72px;
    height: 75px;
    background-position: -667px -483px;
}

.db-l-H0000 {
    width: 57px;
    height: 75px;
    background-position: -5px -568px;
}

.db-l-H0001 {
    width: 57px;
    height: 75px;
    background-position: -72px -568px;
}

.db-l-H0002 {
    width: 57px;
    height: 75px;
    background-position: -139px -568px;
}

.db-l-H0003 {
    width: 57px;
    height: 75px;
    background-position: -206px -568px;
}

.db-l-H0004 {
    width: 57px;
    height: 75px;
    background-position: -273px -568px;
}

.db-l-H0005 {
    width: 57px;
    height: 75px;
    background-position: -340px -568px;
}

.db-l-H0006 {
    width: 57px;
    height: 75px;
    background-position: -407px -568px;
}

.db-l-H0007 {
    width: 57px;
    height: 75px;
    background-position: -474px -568px;
}

.db-l-I0000 {
    width: 69px;
    height: 75px;
    background-position: -541px -568px;
}

.db-l-I0001 {
    width: 69px;
    height: 75px;
    background-position: -620px -568px;
}

.db-l-I0002 {
    width: 69px;
    height: 75px;
    background-position: -5px -653px;
}

.db-l-I0003 {
    width: 69px;
    height: 75px;
    background-position: -84px -653px;
}

.db-l-I0004 {
    width: 69px;
    height: 75px;
    background-position: -163px -653px;
}

.db-l-I0005 {
    width: 69px;
    height: 75px;
    background-position: -242px -653px;
}

.db-l-I0006 {
    width: 69px;
    height: 75px;
    background-position: -321px -653px;
}

.db-l-I0007 {
    width: 69px;
    height: 75px;
    background-position: -400px -653px;
}

.db-l-J0000 {
    width: 41px;
    height: 75px;
    background-position: -699px -568px;
}

.db-l-J0001 {
    width: 41px;
    height: 75px;
    background-position: -479px -653px;
}

.db-l-J0002 {
    width: 41px;
    height: 75px;
    background-position: -530px -653px;
}

.db-l-J0003 {
    width: 41px;
    height: 75px;
    background-position: -581px -653px;
}

.db-l-J0004 {
    width: 41px;
    height: 75px;
    background-position: -632px -653px;
}

.db-l-J0005 {
    width: 41px;
    height: 75px;
    background-position: -683px -653px;
}

.db-l-J0006 {
    width: 41px;
    height: 75px;
    background-position: -755px -5px;
}

.db-l-J0007 {
    width: 41px;
    height: 75px;
    background-position: -737px -90px;
}

.db-l-K0000 {
    width: 75px;
    height: 31px;
    background-position: -723px -313px;
}

.db-l-K0001 {
    width: 75px;
    height: 31px;
    background-position: -723px -354px;
}

.db-l-K0002 {
    width: 75px;
    height: 31px;
    background-position: -5px -738px;
}

.db-l-K0003 {
    width: 75px;
    height: 31px;
    background-position: -90px -738px;
}

.db-l-K0004 {
    width: 75px;
    height: 31px;
    background-position: -175px -738px;
}

.db-l-K0005 {
    width: 75px;
    height: 31px;
    background-position: -260px -738px;
}

.db-l-K0006 {
    width: 75px;
    height: 31px;
    background-position: -345px -738px;
}

.db-l-K0007 {
    width: 75px;
    height: 31px;
    background-position: -430px -738px;
}

.db-l-L0000 {
    width: 41px;
    height: 75px;
    background-position: -733px -175px;
}

.db-l-L0001 {
    width: 41px;
    height: 75px;
    background-position: -747px -395px;
}

.db-l-L0002 {
    width: 41px;
    height: 75px;
    background-position: -749px -480px;
}

.db-l-L0003 {
    width: 41px;
    height: 75px;
    background-position: -750px -565px;
}

.db-l-L0004 {
    width: 41px;
    height: 75px;
    background-position: -750px -650px;
}

.db-l-L0005 {
    width: 41px;
    height: 75px;
    background-position: -734px -735px;
}

.db-l-L0006 {
    width: 41px;
    height: 75px;
    background-position: -806px -5px;
}

.db-l-L0007 {
    width: 41px;
    height: 75px;
    background-position: -788px -90px;
}

// DarkBlue Extra small

.db-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/DarkBlueExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.db-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.db-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.db-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.db-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.db-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.db-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.db-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.db-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.db-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.db-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.db-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.db-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.db-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.db-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.db-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.db-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.db-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.db-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.db-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.db-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.db-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.db-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.db-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.db-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.db-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.db-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.db-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.db-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.db-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.db-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.db-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.db-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.db-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.db-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.db-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.db-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.db-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.db-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.db-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.db-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.db-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.db-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.db-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.db-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.db-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.db-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.db-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.db-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.db-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.db-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.db-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.db-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.db-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.db-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.db-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.db-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.db-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.db-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.db-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.db-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.db-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.db-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.db-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.db-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.db-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.db-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.db-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.db-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.db-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.db-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.db-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.db-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.db-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.db-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.db-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.db-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.db-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.db-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.db-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.db-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.db-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.db-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.db-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.db-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.db-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.db-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.db-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.db-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.db-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.db-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.db-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.db-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.db-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.db-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.db-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.db-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}

// Green Extra Small
.green-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/GreenExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.green-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.green-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.green-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.green-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.green-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.green-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.green-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.green-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.green-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.green-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.green-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.green-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.green-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.green-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.green-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.green-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.green-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.green-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.green-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.green-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.green-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.green-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.green-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.green-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.green-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.green-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.green-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.green-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.green-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.green-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.green-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.green-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.green-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.green-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.green-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.green-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.green-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.green-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.green-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.green-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.green-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.green-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.green-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.green-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.green-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.green-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.green-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.green-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.green-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.green-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.green-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.green-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.green-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.green-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.green-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.green-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.green-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.green-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.green-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.green-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.green-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.green-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.green-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.green-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.green-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.green-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.green-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.green-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.green-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.green-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.green-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.green-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.green-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.green-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.green-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.green-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.green-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.green-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.green-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.green-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.green-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.green-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.green-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.green-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.green-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.green-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.green-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.green-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.green-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.green-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.green-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.green-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.green-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.green-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.green-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.green-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}

// LightBlue Extra Small
.lb-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/LightBlueExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.lb-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.lb-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.lb-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.lb-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.lb-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.lb-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.lb-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.lb-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.lb-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.lb-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.lb-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.lb-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.lb-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.lb-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.lb-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.lb-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.lb-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.lb-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.lb-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.lb-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.lb-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.lb-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.lb-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.lb-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.lb-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.lb-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.lb-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.lb-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.lb-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.lb-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.lb-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.lb-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.lb-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.lb-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.lb-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.lb-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.lb-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.lb-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.lb-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.lb-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.lb-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.lb-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.lb-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.lb-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.lb-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.lb-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.lb-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.lb-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.lb-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.lb-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.lb-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.lb-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.lb-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.lb-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.lb-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.lb-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.lb-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.lb-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.lb-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.lb-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.lb-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.lb-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.lb-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.lb-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.lb-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.lb-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.lb-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.lb-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.lb-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.lb-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.lb-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.lb-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.lb-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.lb-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.lb-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.lb-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.lb-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.lb-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.lb-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.lb-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.lb-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.lb-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.lb-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.lb-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.lb-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.lb-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.lb-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.lb-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.lb-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.lb-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.lb-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.lb-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.lb-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.lb-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.lb-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.lb-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}

//Red Extra small

.red-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/RedExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.red-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.red-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.red-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.red-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.red-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.red-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.red-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.red-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.red-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.red-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.red-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.red-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.red-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.red-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.red-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.red-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.red-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.red-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.red-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.red-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.red-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.red-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.red-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.red-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.red-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.red-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.red-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.red-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.red-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.red-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.red-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.red-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.red-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.red-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.red-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.red-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.red-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.red-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.red-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.red-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.red-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.red-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.red-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.red-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.red-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.red-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.red-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.red-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.red-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.red-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.red-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.red-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.red-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.red-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.red-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.red-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.red-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.red-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.red-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.red-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.red-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.red-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.red-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.red-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.red-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.red-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.red-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.red-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.red-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.red-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.red-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.red-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.red-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.red-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.red-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.red-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.red-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.red-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.red-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.red-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.red-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.red-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.red-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.red-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.red-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.red-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.red-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.red-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.red-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.red-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.red-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.red-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.red-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.red-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.red-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.red-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}

// Yellow Extra Small

.yellow-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/YellowExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.yellow-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.yellow-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.yellow-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.yellow-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.yellow-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.yellow-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.yellow-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.yellow-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.yellow-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.yellow-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.yellow-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.yellow-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.yellow-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.yellow-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.yellow-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.yellow-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.yellow-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.yellow-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.yellow-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.yellow-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.yellow-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.yellow-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.yellow-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.yellow-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.yellow-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.yellow-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.yellow-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.yellow-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.yellow-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.yellow-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.yellow-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.yellow-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.yellow-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.yellow-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.yellow-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.yellow-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.yellow-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.yellow-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.yellow-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.yellow-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.yellow-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.yellow-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.yellow-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.yellow-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.yellow-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.yellow-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.yellow-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.yellow-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.yellow-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.yellow-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.yellow-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.yellow-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.yellow-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.yellow-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.yellow-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.yellow-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.yellow-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.yellow-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.yellow-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.yellow-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.yellow-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.yellow-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.yellow-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.yellow-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.yellow-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.yellow-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.yellow-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.yellow-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.yellow-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.yellow-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.yellow-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.yellow-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.yellow-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.yellow-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.yellow-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.yellow-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.yellow-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.yellow-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.yellow-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.yellow-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.yellow-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.yellow-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.yellow-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.yellow-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.yellow-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.yellow-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.yellow-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.yellow-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.yellow-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.yellow-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.yellow-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.yellow-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.yellow-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.yellow-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.yellow-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.yellow-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}

//White Extra Small

.white-xs {
    background-image: url('https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/sprites/WhiteExtraSmall.webp');
    background-repeat: no-repeat;
    display: block;
}

.white-xs-A0000 {
    width: 32px;
    height: 40px;
    background-position: -5px -5px;
}

.white-xs-A0001 {
    width: 32px;
    height: 40px;
    background-position: -47px -5px;
}

.white-xs-A0002 {
    width: 32px;
    height: 40px;
    background-position: -89px -5px;
}

.white-xs-A0003 {
    width: 32px;
    height: 40px;
    background-position: -131px -5px;
}

.white-xs-A0004 {
    width: 32px;
    height: 40px;
    background-position: -173px -5px;
}

.white-xs-A0005 {
    width: 32px;
    height: 40px;
    background-position: -215px -5px;
}

.white-xs-A0006 {
    width: 32px;
    height: 40px;
    background-position: -257px -5px;
}

.white-xs-A0007 {
    width: 32px;
    height: 40px;
    background-position: -299px -5px;
}

.white-xs-B0000 {
    width: 43px;
    height: 40px;
    background-position: -341px -5px;
}

.white-xs-B0001 {
    width: 43px;
    height: 40px;
    background-position: -394px -5px;
}

.white-xs-B0002 {
    width: 43px;
    height: 40px;
    background-position: -5px -55px;
}

.white-xs-B0003 {
    width: 43px;
    height: 40px;
    background-position: -58px -55px;
}

.white-xs-B0004 {
    width: 43px;
    height: 40px;
    background-position: -111px -55px;
}

.white-xs-B0005 {
    width: 43px;
    height: 40px;
    background-position: -164px -55px;
}

.white-xs-B0006 {
    width: 43px;
    height: 40px;
    background-position: -217px -55px;
}

.white-xs-B0007 {
    width: 43px;
    height: 40px;
    background-position: -270px -55px;
}

.white-xs-C0000 {
    width: 44px;
    height: 40px;
    background-position: -323px -55px;
}

.white-xs-C0001 {
    width: 44px;
    height: 40px;
    background-position: -377px -55px;
}

.white-xs-C0002 {
    width: 44px;
    height: 40px;
    background-position: -5px -105px;
}

.white-xs-C0003 {
    width: 44px;
    height: 40px;
    background-position: -59px -105px;
}

.white-xs-C0004 {
    width: 44px;
    height: 40px;
    background-position: -113px -105px;
}

.white-xs-C0005 {
    width: 44px;
    height: 40px;
    background-position: -167px -105px;
}

.white-xs-C0006 {
    width: 44px;
    height: 40px;
    background-position: -221px -105px;
}

.white-xs-C0007 {
    width: 44px;
    height: 40px;
    background-position: -275px -105px;
}

.white-xs-D0000 {
    width: 40px;
    height: 31px;
    background-position: -329px -105px;
}

.white-xs-D0001 {
    width: 40px;
    height: 31px;
    background-position: -379px -105px;
}

.white-xs-D0002 {
    width: 40px;
    height: 31px;
    background-position: -329px -146px;
}

.white-xs-D0003 {
    width: 40px;
    height: 31px;
    background-position: -379px -146px;
}

.white-xs-D0004 {
    width: 40px;
    height: 31px;
    background-position: -5px -187px;
}

.white-xs-D0005 {
    width: 40px;
    height: 31px;
    background-position: -55px -187px;
}

.white-xs-D0006 {
    width: 40px;
    height: 31px;
    background-position: -105px -187px;
}

.white-xs-D0007 {
    width: 40px;
    height: 31px;
    background-position: -155px -187px;
}

.white-xs-E0000 {
    width: 28px;
    height: 40px;
    background-position: -205px -187px;
}

.white-xs-E0001 {
    width: 28px;
    height: 40px;
    background-position: -243px -187px;
}

.white-xs-E0002 {
    width: 28px;
    height: 40px;
    background-position: -281px -187px;
}

.white-xs-E0003 {
    width: 28px;
    height: 40px;
    background-position: -319px -187px;
}

.white-xs-E0004 {
    width: 28px;
    height: 40px;
    background-position: -357px -187px;
}

.white-xs-E0005 {
    width: 28px;
    height: 40px;
    background-position: -395px -187px;
}

.white-xs-E0006 {
    width: 28px;
    height: 40px;
    background-position: -5px -237px;
}

.white-xs-E0007 {
    width: 28px;
    height: 40px;
    background-position: -43px -237px;
}

.white-xs-F0000 {
    width: 42px;
    height: 40px;
    background-position: -81px -237px;
}

.white-xs-F0001 {
    width: 42px;
    height: 40px;
    background-position: -133px -237px;
}

.white-xs-F0002 {
    width: 42px;
    height: 40px;
    background-position: -185px -237px;
}

.white-xs-F0003 {
    width: 42px;
    height: 40px;
    background-position: -237px -237px;
}

.white-xs-F0004 {
    width: 42px;
    height: 40px;
    background-position: -289px -237px;
}

.white-xs-F0005 {
    width: 42px;
    height: 40px;
    background-position: -341px -237px;
}

.white-xs-F0006 {
    width: 42px;
    height: 40px;
    background-position: -393px -237px;
}

.white-xs-F0007 {
    width: 42px;
    height: 40px;
    background-position: -5px -287px;
}

.white-xs-G0000 {
    width: 39px;
    height: 40px;
    background-position: -57px -287px;
}

.white-xs-G0001 {
    width: 39px;
    height: 40px;
    background-position: -106px -287px;
}

.white-xs-G0002 {
    width: 39px;
    height: 40px;
    background-position: -155px -287px;
}

.white-xs-G0003 {
    width: 39px;
    height: 40px;
    background-position: -204px -287px;
}

.white-xs-G0004 {
    width: 39px;
    height: 40px;
    background-position: -253px -287px;
}

.white-xs-G0005 {
    width: 39px;
    height: 40px;
    background-position: -302px -287px;
}

.white-xs-G0006 {
    width: 39px;
    height: 40px;
    background-position: -351px -287px;
}

.white-xs-G0007 {
    width: 39px;
    height: 40px;
    background-position: -400px -287px;
}

.white-xs-H0000 {
    width: 31px;
    height: 40px;
    background-position: -5px -337px;
}

.white-xs-H0001 {
    width: 31px;
    height: 40px;
    background-position: -46px -337px;
}

.white-xs-H0002 {
    width: 31px;
    height: 40px;
    background-position: -87px -337px;
}

.white-xs-H0003 {
    width: 31px;
    height: 40px;
    background-position: -128px -337px;
}

.white-xs-H0004 {
    width: 31px;
    height: 40px;
    background-position: -169px -337px;
}

.white-xs-H0005 {
    width: 31px;
    height: 40px;
    background-position: -210px -337px;
}

.white-xs-H0006 {
    width: 31px;
    height: 40px;
    background-position: -251px -337px;
}

.white-xs-H0007 {
    width: 31px;
    height: 40px;
    background-position: -292px -337px;
}

.white-xs-I0000 {
    width: 37px;
    height: 40px;
    background-position: -333px -337px;
}

.white-xs-I0001 {
    width: 37px;
    height: 40px;
    background-position: -380px -337px;
}

.white-xs-I0002 {
    width: 37px;
    height: 40px;
    background-position: -5px -387px;
}

.white-xs-I0003 {
    width: 37px;
    height: 40px;
    background-position: -52px -387px;
}

.white-xs-I0004 {
    width: 37px;
    height: 40px;
    background-position: -99px -387px;
}

.white-xs-I0005 {
    width: 37px;
    height: 40px;
    background-position: -146px -387px;
}

.white-xs-I0006 {
    width: 37px;
    height: 40px;
    background-position: -193px -387px;
}

.white-xs-I0007 {
    width: 37px;
    height: 40px;
    background-position: -240px -387px;
}

.white-xs-J0000 {
    width: 22px;
    height: 40px;
    background-position: -287px -387px;
}

.white-xs-J0001 {
    width: 22px;
    height: 40px;
    background-position: -319px -387px;
}

.white-xs-J0002 {
    width: 22px;
    height: 40px;
    background-position: -351px -387px;
}

.white-xs-J0003 {
    width: 22px;
    height: 40px;
    background-position: -383px -387px;
}

.white-xs-J0004 {
    width: 22px;
    height: 40px;
    background-position: -415px -387px;
}

.white-xs-J0005 {
    width: 22px;
    height: 40px;
    background-position: -447px -5px;
}

.white-xs-J0006 {
    width: 22px;
    height: 40px;
    background-position: -431px -55px;
}

.white-xs-J0007 {
    width: 22px;
    height: 40px;
    background-position: -429px -105px;
}

.white-xs-K0000 {
    width: 40px;
    height: 16px;
    background-position: -5px -155px;
}

.white-xs-K0001 {
    width: 40px;
    height: 16px;
    background-position: -55px -155px;
}

.white-xs-K0002 {
    width: 40px;
    height: 16px;
    background-position: -105px -155px;
}

.white-xs-K0003 {
    width: 40px;
    height: 16px;
    background-position: -155px -155px;
}

.white-xs-K0004 {
    width: 40px;
    height: 16px;
    background-position: -205px -155px;
}

.white-xs-K0005 {
    width: 40px;
    height: 16px;
    background-position: -255px -155px;
}

.white-xs-K0006 {
    width: 40px;
    height: 16px;
    background-position: -429px -155px;
}

.white-xs-K0007 {
    width: 40px;
    height: 16px;
    background-position: -433px -181px;
}

.white-xs-L0000 {
    width: 22px;
    height: 40px;
    background-position: -445px -207px;
}

.white-xs-L0001 {
    width: 22px;
    height: 40px;
    background-position: -449px -257px;
}

.white-xs-L0002 {
    width: 22px;
    height: 40px;
    background-position: -449px -307px;
}

.white-xs-L0003 {
    width: 22px;
    height: 40px;
    background-position: -447px -357px;
}

.white-xs-L0004 {
    width: 22px;
    height: 40px;
    background-position: -447px -407px;
}

.white-xs-L0005 {
    width: 22px;
    height: 40px;
    background-position: -479px -5px;
}

.white-xs-L0006 {
    width: 22px;
    height: 40px;
    background-position: -463px -55px;
}

.white-xs-L0007 {
    width: 22px;
    height: 40px;
    background-position: -461px -105px;
}



.tacticsboard__feature_container{
    .tacticsboard__feature_icons{
        max-height: calc(100vh - 220px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}
.tacticsboard__modal_wrap {
    .ant-tabs-tab-btn{
        font-weight: bold;
        color: white;
    }
}



/* width */
.tacticsboard__feature_icons::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.tacticsboard__feature_icons::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.tacticsboard__feature_icons::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility:hidden;
}
.tacticsboard__feature_icons:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}


/* width */
.sprites-container::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.sprites-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.sprites-container::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
    visibility:hidden;
}
.sprites-container:hover::-webkit-scrollbar-thumb {
    visibility:visible;
}

@media only screen and (max-width: 992px){
    .sprites-container_rotation{
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: space-between !important;
        height: 120px !important;
        width: 40px !important;
        padding: 4px !important;
        border-radius: 8px !important;
    }
    .sprites-container{
        margin-top: 2px;
        max-height: calc(100vh - 205px);
    }
    .sprites-mobile{
        display: flex;
        align-items: center;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: flex-start;
    }
    .goalKeeper_container{
        max-height: calc(100vh - 190px);
    }
    .tacticsboard_formated_title{
        display: none;
    }
    .tactictics__board_drawer {
        .ant-tabs-tab-btn{
            font-weight: bold;
            color: white;
        }
    }
    .tacticsboard__feature_container{
        .tacticsboard__feature_icons{
            max-height: calc(100vh - 205px);
        }
        .tacticsboard__feature_container-height{
            max-height: calc(100vh - 170px);
        }
    }
}
@media(max-width: 768px){
    .sprites-container {
        max-height: calc(100vh - 210px) !important;
    }
}
//////////////

