.tacticsboard__animations_frames {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tacticsboard__animations_frames[dir="ltr"] {
    margin-left: 16px;
}
.tacticsboard__animations_frames[dir="rtl"] {
    margin-right: 16px;
}

.tacticsboard__animations_frames_add {
    font-size: 12px !important;
}
.tacticsboard__animations_frames_add[dir="ltr"] {
    margin-left: 4px !important;
}
.tacticsboard__animations_frames_add[dir="rtl"] {
    margin-right: 4px !important;
}

.tacticsboard__animations_frame {
    width: 24px;
    height: 24px;
    font-size: 14px;
    margin: auto 1px;
    font-weight: 400;
    border-radius: 15px;
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}

.tacticsboard__animations_frame_remove {
    top: -4px;
    right: -4px;
    width: 13px;
    height: 13px;
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: absolute;
    align-items: center;
    border-radius: 13px;
    justify-content: center;
    > span {
        font-size: 11px;
        margin-top: -3px;
    }
}
.speed-bar {
    height: 24px;
    padding: 0px 2px;
    margin-left: 4px;
    display:flex;
    margin-right: 5px !important;
    justify-content: space-evenly;
    background: #FFFFFF;
    border: 1px solid darkgray;
    border-radius:4px ;
    align-items: center;
    color: black;
    width: 60px !important;
    cursor: pointer;
}
.disabled_speed-bar{
        pointer-events: none;
        opacity: 0.4;
}
.time-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
}