.d__none{
  display: none !important;
}

.pg__container {
  width: 100%;
  border: none !important;
  padding: 16px 24px 24px;
  @media (max-width: 768px) {
      padding: 12px 0px 16px;
  }
}
.pg__container.save__modal {
  padding: 16px 24px 64px;
  @media (max-width: 768px) {
      padding: 12px 0px 64px;
  }
}

.container__mobile {
  width: 100%;
  display: none !important;
  @media (max-width: 1024px) {
    display: block !important;
  }
}

.container__desktop {
  width: 100%;
  display: block !important;
  @media (max-width: 1024px) {
    display: none !important;
  }
}

.danger__on_hover:hover {
  color: #ffffff !important;
  background-color: #f44336 !important;
}

.ant-tabs {
  @media (max-width: 1024px) {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.color__black {
  color: #000000 !important;
}
.color__white {
  color: #ffffff !important;
}

.color__primary {
  color: #1890ff !important;
}

.background__color_black {
  background-color: #000000 !important;
}
.background__color_white {
  background-color: #ffffff !important;
}

.color__light_grey {
  color: #757575 !important;
}
.color__medium_grey {
  color: #616161 !important;
}
.color__dark_grey {
  color: #424242 !important;
}

.color__red {
  color: #e53935 !important;
}
.color__green {
  color: #43a047 !important;
}
.color__blue {
  color: #1e88e5 !important;
}
.color__yellow {
  color: #ffb300 !important;
}

.background__red {
  background-color: #e53935 !important;
}
.background__green {
  background-color: #43a047 !important;
}
.background__blue {
  background-color: #1e88e5 !important;
}
.background__yellow {
  background-color: #ffb300 !important;
}
.background__gray{
  color: #000000;
  background-color: #424345 !important;
}
.background__white {
  color: #000000;
  background-color: #ffffff !important;
}

.black__border_light {
  border: 1px solid #8E8C8A !important;
}
.newGray__border_light {
  border: 1px solid #8E8C8A !important;
}

.black__border_heavy {
  border: 4px solid #ffffff !important;
}
.blue__border_heavy {
  border: 3px solid #5A5FE7 !important;
}

.border__simple {
  border: 1px solid #ccc!important;
}

.box__shadow {
  box-shadow: 1px 1px 5px 0.1px;
}
.ant-modal-header{
  border-bottom: 1px solid #000000 !important;
}
.ant-modal-wrap{
  overflow: hidden!important;
}