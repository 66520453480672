.tacticsboard__colors_container {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 200px;
    left: calc(0% + 216px );
    bottom: 5px;
    z-index: 9999;
    border-radius: 10px;
}
.tacticsboard__colors_container-r {
    display: flex;
    flex-direction: column;
    position: fixed;
    width: 200px;
    left: calc(100vw - 445px) !important;
    top: calc(100vh - 145px) !important;
    bottom: 5px;
    z-index: 9999;
    border-radius: 10px;
}

.tacticsboard__colors_color {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 22px;
}

.tacticsBoard_colors_header{
    cursor: move;
    flex: 0 0 52px;
    background: #5A5FE7;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 13px 0px 23px ;
    border-radius: 10px;
}
.tacticsBoard_colors_header span{
    font-size: 20px !important;
    line-height: 28px !important;
}
.tacticsBoard_colors_header button{
    background: transparent !important;
}
.tacticsBoard_colors_body{
    flex: 0 0 45px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
}
@media screen and (max-width: 992px){
    .tacticsboard__colors_container{
        padding: 0px 0px 12px 0px !important;
        bottom: 50px !important;
        left: 50% !important;

    }
}