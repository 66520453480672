.tacticsboard__header_lft_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .PitchesDropDown{
        .ant-btn-icon-only{
            vertical-align: 0px!important;
        }
    }
}

.tacticsboard__header_lft_btn {
    border: none !important;
    color: #EEECE9;
}

.tacticsboard__header_lft_container[dir="ltr"] .tacticsboard__header_lft_btn {
    background-color: transparent;
    margin-right: 6px !important;
    @media(max-width: 992px){
        margin-right: 0px !important;
        padding: 6px 12px;
        width: 60px;
        height: 48px;
        //background: #ffffff !important;
        border-width: 1px 1px 0px 1px;
        border-bottom: 1px solid #E9E9EB !important;
        border-style: solid;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    @media(max-width: 768px){
        height: 40px !important;
    }
}
.tacticsboard__header_lft_container[dir="rtl"] .tacticsboard__header_lft_btn {
    margin-left: 6px !important;
    @media(max-width: 992px){
        padding: 6px 12px;
        width: 60px;
        height: 48px;
        background: #F5F6F7;
        border-width: 1px 1px 0px 1px;
        border-style: solid;
        border-color: #D7D8D9;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    @media(max-width: 768px){
        height: 40px !important;
    }
}

.tacticsboard__header_lft_icn {
    font-size: 16px !important;
    margin-bottom: -3px !important;
    @media(max-width: 992px){
        font-size: 14px !important;
        margin-bottom: -2px !important;
    }
    > svg {
        fill: white !important;
    }
}

.tacticsboard__header_lft_extra_dt_container {
    @media(max-width: 992px){
        display: none;
    }
}

.tacticsboard__header_lft_icn1 {
    font-size: 16px !important;
    margin-bottom: -3px !important;
    @media(max-width: 992px){
        font-size: 14px !important;
        margin-bottom: -2px !important;
    }
    > svg {
        fill: white !important;
    }
}

.tacticsboard__header_lftextra_dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.tacticsBoard_logo{
    cursor: pointer;
    width: 200px;
    height: 53px;
    left: 2px;
    top: 0px;
}

.setting_border{
    padding-right: 10px;
    border-right: 1px solid rgba(238, 236, 233, 0.35);
    margin-right: 10px;
}
.component_editor_modal{
    display: flex;
    flex-direction: column;
    position: fixed;
    min-width: 330px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 10px;
}
.editor_modal_header{
    flex: 0 0 52px;
    background: #5A5FE7;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 13px 0px 0px ;
    border-radius: 10px 10px 0px 0px;
}
.draggable_div{
    cursor: move;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    height: 52px;
}
.editor_modal_header img{
    cursor: move;
    margin-left: -50px;
}
.editor_modal_header span{
    font-size: 20px !important;
    line-height: 28px !important;
}
.editor_modal_header button{
    background: transparent !important;
}
.editor_modal_body{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    flex: 0 0 118px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
}
.disp_none{
    display: block !important;
}
.disp_res_none{
    display: none !important;
}
@media screen and (max-width: 992px) {
    .button_inner_text{
        font-weight: normal;
        font-size: 8px;
        color: black;
    }
    .disp_none{
        display: none !important;
    }
    .editor_setting{
        border-bottom: 1px solid #E9E9EB !important;
    }
    .disp_res_none{
        display: block !important;
    }
    .tacticsboard__header_lft_container{
        flex-direction: column;
        overflow-y: auto;
        .tacticsBoard_logo{
            display: none;
        }
    }
    .component_editor_modal{
        .editor_modal_body{
            flex: 0 0 100px !important;
        }
        .editor_modal_header{
            flex: 0 0 40px;
        }
        .draggable_div{
            height: 40px;
        }
        .editor_modal_header span{
            font-size: 18px !important;
            line-height: 20px !important;
        }
    }
}

