.tacticsboard__header_lftextra_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.text-options{
    width: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.tacticsboard__header_lftextra_container[dir="ltr"] {
    @media(max-width: 992px) {
        border-left: none;
        padding-left: 0px;
    }
}

.tacticsboard__header_lftextra_container[dir="rtl"] {

    @media(max-width: 992px) {
        border-right: none;
        padding-right: 0px;
    }
}

.tacticsboard__header_lftextra_btn {
    margin-left: 0px !important;
    border: none !important;
}

.tacticsboard__header_lftextra_container[dir="ltr"] .tacticsboard__header_lftextra_btn {
    background-color: transparent;
    margin-left: 12px !important;

    @media(max-width: 768px) {
        margin-left: 8px !important;
    }
}

.tacticsboard__header_lftextra_container[dir="rtl"] .tacticsboard__header_lftextra_btn {
    background-color: transparent;
    margin-right: 12px !important;
    @media(max-width: 768px) {
        margin-right: 8px !important;
    }
}

.tacticsboard__header_lftextra_container[dir="ltr"] .tacticsboard__header_lftextra_btn_txt {
    margin-left: 20px !important;
    @media(max-width: 768px) {
        margin-left: 16px !important;
    }
}

.tacticsboard__header_lftextra_container[dir="rtl"] .tacticsboard__header_lftextra_btn_txt {
    margin-right: 20px !important;
    @media(max-width: 768px) {
        margin-right: 16px !important;
    }
}

.tacticsboard__header_lftextra_icn {
    color: white;
    font-size: 24px !important;
}

.tacticsboard__header_lftextra_icn.active {
    font-size: 24px !important;
}

.tacticsboard__header_extra_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    @media(max-width: 768px) {
        justify-content: center;
    }
}

.tacticsboard__header_lftextra_input {
    color: black;
    font-size: 14px !important;
    border-radius: 4px !important;
    background-color: white !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
}

.drop-down {
    margin-top: -1px;
    cursor: pointer;
    margin-left: 0px;
}

dropdown-layer {
    margin-bottom: 10px;
}

.text-popup {
    color: black;
    font-size: 14px !important;
    border-radius: 4px !important;
    background-color: rgba(255, 255, 255, 0.9);
    width: 200px;
    padding: 10px;
    position: absolute;
    top: 38px !important;
    left: 26rem !important;
}

.tacticsboard__modal_wrap {
    .pg__form_input {
        border: 2px solid #ccc !important;
        border-radius: 4px !important;
    }
    .popup-content{
        display: flex;
        margin: 0px 15px;
        padding-bottom: 5px;
    }
    .pg__form_label {
        cursor: default;
        color: #0a0a0a;
        font-family: "Rubik", Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
    }
    .angle_setting .size_setting{
        display: flex;
        width: 15rem;
    }
    .angle_setting button{
        height: 25px !important;
        width: 80px !important;
    }
    .size_setting button{
        height: 25px !important;
        width: 80px !important;
    }
    .ant-input {
        max-width: 80% !important;
    }

    .bg_purple {
        background-color: #6E6DD3 !important;
    }
}

.text-bottom {
    margin-top: 5px;
}

.text-elem {
    color: black;
    font-size: 14px !important;
    border-radius: 4px !important;
    background-color: rgba(255, 255, 255, 0.6);
    width: 200px;
    padding: 10px;
    margin-top: 170px;
    position: relative;
}

.d__none {
    display: none !important;
}
.ml-5 {
    margin-left: -5px !important;
}

.tacticsboard__modal_wrap {
    .leftSide_Opacity {
         width: 15rem;
        margin: 0px 10px;
        display: inline-block;
    }
    .transparency_style {
        font-size: 12px !important;
        color: #fff;
    }
    .boldBtn {
        font-weight: 700;
        font-size: 1.8vw !important;
        border: none;
        border-radius: 5px;
        height: 33px;
        width: 33px;
        margin-left: 3px;
    }
    .editor_element{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        height: 45px;
        width: 50px;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #EEECE9;
    }
    .editor_element:hover{
        background: #F5F6F7;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #EEECE9;
    }
    .editor_element button{
        background: transparent;
    }
    .layer_setting button{
        padding: 6px 20px !important;
        border-radius: 6px !important;
        background: #5A5FE7 !important;
        color: #FFFFFF;
        margin-right: 5px;
        border: none;
        cursor: pointer;
    }
    .layer_setting button:hover{
        background: #686cd7 !important;
    }
    .modal_body_properties{
        display: flex;
        flex: 0 0 45px;
    }
    .modal_body_color{
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        padding: 0px 10px;
    }
    .line_width_setting{
        width: 15rem;
    }
    .tacticsboard__header_extra_item {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
    .line-width-column{
        max-width: 40px !important;
        cursor: pointer;
    }
    .line-width-svg-l0{
        background-color: #000c17 !important;
        display: block;
        width: 35px;
        height:1px;
        cursor: pointer;

    }
    .line-width-svg-l1{
        background-color: #000c17 !important;
        display: block;
        width: 35px;
        height:2.5px;
        cursor: pointer;

    }
    .line-width-svg-l2{
        background-color: #000c17 !important;
        width: 35px;
        height:4px;
        cursor: pointer;
    }
    .line-width-svg-l3{
        background-color: #000c17 !important;
        width: 35px;
        height:6px;
        cursor: pointer;
    }
    .line-width-svg-l4{
        background-color: #000c17 !important;
        width: 35px;
        height:7px;
        cursor: pointer;
    }
    .line-width-svg-l5{
        background-color: #000c17 !important;
        width: 35px;
        height:9px;
        cursor: pointer;
    }
    .shapeBorderStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20rem;
        label{
            width: 40%;
        }
        input {
            visibility:hidden;
        }
        .dashedLine{
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }
        .simpleLine{
            margin-left: 5px;
            color: #fff;
            background-color: #666666;
            height: 35px;
            padding: 8px;
            cursor: pointer;
        }
        .dashedLine hr{
            border: none;
            border-top: 6px dashed #fff;
            width: 98%;
            margin-top: 7px;
        }
        .simpleLine hr{
            border: none;
            border-top: 6px solid #fff;
            background-color: #fff;
            width: 98%;
            margin-top: 7px;
        }
        .dottedStyle{
            background-color: #6E6DD3;
        }
    }
}

@media(max-width: 992px) {
    .angle_setting{
        display: flex;
        width: 15rem;
    }
    .editor_element{
        display: flex !important;
        justify-content: end !important;
        height: 50px !important;
        flex-direction: column;
        align-items: center;
    }
    .boldBtn {
        font-size: 3.8vw !important;
    }
    .text-popup,
    .text-elem {
        top: 44px !important;
        left: 12rem !important;
    }
    .tacticsboard_tooltip {
        display: none !important;
    }

    .tacticsboard__header_lftextra_container {
        .ant-btn-icon-only.ant-btn-sm {
            width: 26px !important;
        }
    }
}