.tacticsboard__animations_conatiner {
    bottom: 5px;
    display: flex;
    position: fixed;
    padding: 0px 16px;
    align-items: center;
    justify-content: center;
}
.w_100{
    width: 100% !important;
    height: 35px!important;
}
.animation_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36.4px;
    background: #D9D9D9;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 0px 16px;
}
.animation_wrapper_main{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px 0px 0px 25px;
    padding: 0px 30px;
    background: #5A5FE7;
}
.animation_controls{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 36.6px;
    background: #BEBEBF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0px 10px 10px 0px;
}
.tacticsboard__animations_actions_icon {
    display: flex;
    cursor: pointer !important;
    margin: auto 3px !important;
}

.opacity__low{
    opacity: .5;
}
.d__none{
    display: none !important;
}
.disabled{
    pointer-events: none
}
@media(max-width: 992px){
    .tacticsboard__animations_conatiner {
        width: 100% !important;
        left: calc(0% - -50px) !important;
    }
}