.pleaseRotate {
    height: 120vh;
    width: 100%;
    background-color: #000c17;
    z-index: 2147483647;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
}

.pleaseRotate-inner {
    transform: translate(0%, 250%);
}

.phone {
    margin: auto;
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    animation: rotate 1.5s ease-in-out infinite alternate;
}

.message {
    color: white;
    font-size: 1em;
    margin: 40px auto auto;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(-90deg)
    }

    100% {
        transform: rotate(-90deg)
    }
}

@media only screen and (max-width: 475px) and (orientation: portrait) {
    .pleaseRotate {
        display: block;
    }
}

@media only screen and (orientation: landscape) {
    .pleaseRotate {
        display: none;
    }
}