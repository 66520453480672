.tacticsboard__header_rgt_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tacticsboard__header_rgt_btn {
    border: none !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.dwn-container{
    display: flex;
    flex-direction: column;
    height: auto;
}
.tacticsboard__header_rgt_container[dir="ltr"] .tacticsboard__header_rgt_btn {
    background-color: transparent;
    margin-left: 8px !important;
}
.tacticsboard__header_rgt_container[dir="rtl"] .tacticsboard__header_rgt_btn {
    margin-right: 8px !important;
}
.divider {
    position: relative;
}

.divider::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    border-bottom: 2px solid black; /* for compatibility with older browsers */
}
.tacticsboard__header_rgt_btn_icn {

    font-size: 11px !important;
}
.tacticsboard__header_rgt_container[dir="ltr"] .tacticsboard__header_rgt_btn_icn {

    margin-right: 3px !important;
}
.tacticsboard__header_rgt_container[dir="rtl"] .tacticsboard__header_rgt_btn_icn {

    margin-left: 3px !important;
}

.tacticsboard__header_rgt_icn {
    font-size: 24px !important;
    margin-bottom: -5px !important;
    > svg {
        fill: white !important;
    }
}
.tacticsboard__header_rgt_icn1 {
    font-size: 24px !important;
    margin-bottom: -5px !important;
    > svg {
        fill: white !important;
    }
}
.done_btn_color{
    background-color: #000000 !important;
    color: white!important;
}
.tacticsboard__header_rgt_btn:disabled,
Button[disabled]{
    background-color: #666666 !important;
    opacity: .7;
}
.d_none{
    display: block !important;
}
.disp_none{
    display: block !important;
}

.downloadMargin{
    margin-right: 10px!important;
}
.animationStyle{
    margin-left: 0px !important;
    margin-right: 10px !important;
    padding-left: 4px !important;
}
.downloadImage{
    padding: 0px 2px!important;
}
.tacticsBoard_rgt_btn{
    display: flex !important;
    flex-direction: row;
    align-items: center;
    padding: 6px 20px !important;
    border-radius: 6px !important;
    margin-right: 17px;
}
.button_inner_text{
    color: #5A5FE7;
    margin: 0px 5px;
    font-weight: bold;
}
.button_inner_text_dark{
    color: #000;
    margin: 0px 5px;
    font-weight: bold;
}
.tacticsBoard_rgt_icon{
    background: transparent !important;
    width: auto !important;
    color: #fff !important;
}
.rgt_small_button{
    display: flex !important;
    justify-content: center;
    align-items: end;
    background: transparent !important;
    color: #fff !important;
    margin-right: 17px;
    width: auto !important;
}
.help_btn_border{
    margin-top: 5px;
    margin-right: 12px !important;
    padding-right: 12px!important;
    border-right: 1px solid rgba(238, 236, 233, 0.35);
}
.disp_res_none{
    display: none !important;
}
@media screen and (max-width: 992px){
    .disp_none{
        display: none !important;
    }
    .mr-0{
        margin-right: 0px !important;
    }
    .animationStyle, .downloadStyle{
        width: 27px!important;
    }
    .downloadMargin{
        margin-right: 2px!important;
    }
    .d_none{
        display: none !important;
    }
    .disp_res_none{
        display: block !important;
    }
    .tacticsboard__header_rgt_container {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: calc(100vh - 20%);
        width: 100%;
    }
    .tacticsboard__header_rgt_container[dir="ltr"] .tacticsboard__header_rgt_btn {
        margin-bottom: 8px;
        margin-left: 0px !important;
        padding: 0px 2px !important;
    }
    .mr-3{
        margin-right: 3px !important;
    }
    .mb-5{
        margin-bottom: 5px !important;
    }
    .mb-12{
        margin-bottom: 12px !important;
    }
    .custom_style_drawingBtn{
        display: flex !important;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin: 5px 0px;
        font-size: 9px !important;
    }
    .custom_style_done{
        position: fixed !important;
        font-size: 10px !important;
        padding: 0px 13px !important;
        bottom: calc(0% - -0.5rem) !important;
        left: 0px;
    }
    .rgt_small_button{
        margin-right: 0px;
        color: #0a0a0a !important;
        padding: 6px 6px !important;
        width: auto !important;
        height:auto !important;
        background: #ffffff !important;
        //border-bottom: 1px solid #E9E9EB !important;
        display: flex ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg{
            margin: auto !important;
            font-size: var(--font-size-ratio);
        }
    }

    .top-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid #e9e9eb !important;
    }
    .btm-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid #E9E9EB !important;
    }
    .tacticsboard__header_rgt_container{
        .ant-btn{
            //border-bottom: 1px solid #E9E9EB !important;
        }
    }
}
:root {
    --font-size-ratio: 1.4vw; /* Set the font size as 1.4% of the viewport width */
}