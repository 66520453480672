.color_change_div {
    height: 17px;
    width: 17px;
    border: 2px solid black;
    border-radius: 10px;
    background-color: #003E60;
    cursor: pointer;
}
//.app-simple-drawer {
//    z-index: 1300 !important;
//}

.tacticsboard__modal_wrap {
    .ant-modal{
        font-family: rubik;
        &.tacticsboard__modal{
            .ant-btn-icon-only.ant-btn-sm {
                background-color: transparent;
            }
            .ant-modal{
                &-header{
                    flex: 0 0 52px;
                    margin: 0px !important;
                    padding: 10px 8px;
                    background-color: #5a5fe7;
                    border-radius: 0px !important;
                }
                &-body{
                    flex: 1 1 auto;
                    padding: 0px !important;
                    display: flex !important;
                    @media(max-width: 992px) {
                        margin: 0px !important;
                        padding: 0px !important;
                        margin-top: 0px !important;
                    }
                }
                &-content{
                    display: flex !important;
                    flex-direction: column !important;
                    background-color: #393836 !important;
                    height: auto !important;
                    min-height: 100vh !important;
                }
            }
            .flex_auto{
                flex-flow: row !important;
                width: 100% !important;
            }
        }
    }

    // RADIOS 
    .ant-radio-wrapper {
        span {
            color: #9E9E9E !important;
            font-weight: 500 !important;
        }
    }

    .ant-radio-wrapper.ant-radio-wrapper-checked {
        span {
            color: #fff !important;
            font-weight: 500 !important;
        }
    }

    // TABS 
    .ant-tabs-top>.ant-tabs-nav,
    .ant-tabs-bottom>.ant-tabs-nav,
    .ant-tabs-top>div>.ant-tabs-nav,
    .ant-tabs-bottom>div>.ant-tabs-nav {
        margin: 0 0 8px !important;
    }

    .ant-tabs-tab {
        font-size: 11px !important;
        padding: 4px 0px !important;
    }

    .ant-tabs {
        overflow: visible !important;
        .ant-tabs-tab-btn {
            color: #ffffff !important;
            border: none !important;
        }

        .ant-tabs-tab-btn:hover,
        :focus {
            border: none !important;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            font-weight: 500 !important;
            color: #fff !important;
        }

        .ant-tabs-ink-bar {
            color: red !important;
            border-color: #fff !important;
            ;
            background-color: #fff !important;
            ;
            border-bottom: #fff !important;
            ;
        }

        .ant-tabs-tabpane-active {
            border: none !important;
        }

        .ant-tabs-tabpane-active:hover,
        :focus {
            border: none !important;
        }

        .ant-tabs-nav .ant-tabs-tab {
            margin-left: 0px !important;
        }
    }

    .ant-tabs-rtl {
        .ant-tabs-tab {
            margin-right: 0px !important;
            margin-left: 20px !important;
        }

        .ant-tabs-nav .ant-tabs-tab {
            margin-right: 0px !important;
            margin-left: 20px !important;
        }
    }
    .ant-modal.tacticsboard__modal1 {
        @media(max-width: 992px) {
            top: 0px !important;
        }
        .ant-modal-header {
            flex: 0 0 52px;
            margin: 0px !important;
            padding: 10px 8px;
            background-color: #5a5fe7;
            color: black;
            border-radius: 0px !important;
        }
        .ant-btn-icon-only.ant-btn-sm {
            background-color: transparent;
        }
        .ant-modal-body {
            flex: 1 1 auto;
            padding: 0px !important;
            display: flex !important;
            @media(max-width: 992px) {
                margin: 0px !important;
                padding: 0px !important;
            }
        }
        .flex_auto{
            flex-flow: row !important;
            width: 100% !important;
        }
        .ant-modal {
            position: relative !important;
            top: 0 !important;
            max-height: 100vh;
            overflow: hidden;
        }
        .ant-modal-content {
            display: flex !important;
            flex-direction: column !important;
            background-color: #393836 !important;
            height: auto !important;
            min-height: 100vh !important;
            padding: 0px !important;

        }
    }
    //.tacticsboard-tools-top {
    //    display: flex;
    //    margin-top: -15px;
    //    margin-right: -23px;
    //}
    .ant-modal-close {
        display: none !important;
    }
    .ant-btn-default {
        background-color: #FFFFFF;
        border-color: #FFFFFF;
    }
}

#pitchContainerBox {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
     @media(max-width: 992px){
         align-items: normal!important;
     }
}

.tacticsboard__pitch_container canvas {
    width: 100%;
}

.ant-btn {
    border: 0 !important;
}

.d__none {
    display: none !important;
}

.canvas-container {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.tacticsboard__header_lft_extra_mb_container {
    display: none;
}

.tacticsboard__tools_modal_container {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    background-color: #424345;
}

.PathShadowHolder {
    position: absolute;
    left: 0;
    width: 200px;
    display: none;
}

.PathShadowHolder .PathHolderElement {
    display: none;
}

.PathShadowHolder .PathHolderElement .svg-path {
    stroke: rgb(0, 0, 0);
    stroke-width: 1;
    stroke-dasharray: none;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-miterlimit: 10;
    fill: none;
    fill-rule: nonzero;
    opacity: 1;
}

.tactictics__board_drawer {
    .shirt_no_left {
        display: flex;
        flex-direction: row-reverse;
    }

    .shirt_no_right {
        display: flex;
        flex-direction: row;
    }

    .add_custom_img{
        color: white;
        display: flex;
        justify-content: end;
        margin-top: -40px;
        margin-right: 10px;
        font-size: 22px;
        font-weight: bold;
        padding: 2px;
        cursor: pointer;
    }

    .ant-drawer-content-wrapper {
        width: 216px !important;
    }
    .ant-drawer-content{
        overflow: visible;
    }
    .ant-drawer-title {
        color: #fff !important;
        font-weight: bold;
        font-family: Roboto;
    }

    .ant-drawer-header {
        padding: 7px !important;
        background: #6E6DD3 !important;
    }

    .ant-drawer-body {
        display: flex;
        padding: 0px !important;
        background: #000000 !important;
    }

    .tacticsboard__feature_icon img {
        width: 2.5rem !important;
        height: 2.5rem !important;
        @media(max-width: 992px){
            width: 1.8rem !important;
            height: 1.8rem !important;
        }
    }

    .tacticsboard__draw_img {
        width: 2.5rem !important;
        height: 2.5rem !important;
        @media(max-width: 992px){
            width: 1.8rem !important;
            height: 1.8rem !important;
        }
    }

    .svg__number_icon {
        >img {
            height: 2.5rem !important;
            width: 2.5rem !important;
            border-radius: 28px;
            border: 3px solid #fff;
        }
    }
}

.menu_button {
    display: none !important;
}

.fullScreen_button {
    display: none;
}

#tacticsboard_tools {
    position: absolute;
}

.d_none {
    display: none !important;
}
.left_header_menu {
    display: none !important;
}

@media screen and (max-width: 992px) {
    .d_none {
        display: none !important;
    }
    .previousDrawing_main{
        display: flex !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        white-space: break-spaces !important;
        height: 70px !important;
        width: 32px !important;
        font-size: 10px !important;
    }
    .left_header_menu{
        flex: 0 0 52px;
        margin: 0px !important;
        background-color: #FFFFFF;
        color: black;
        border-radius: 0px !important;
        position: absolute;
        overflow: auto;
        left: 0px;
        width: 60px;
        height: 100vh;
        display: flex !important;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .menu_button{
        margin-right: 0px !important;
        padding: 6px 12px !important;
        width: 60px !important;
        height: 50px !important;
        background: #ffffff !important;
        border-width: 1px 1px 0px 1px;
        border-bottom: 1px solid #E9E9EB !important;
        border-style: solid;
        display: flex !important;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media(max-width: 768px){
            height: 40px !important;
        }
    }
    .tactictics__board_drawer {
        .svg__number_icon {
            >img {
                height: 2.6rem !important;
                border-radius: 28px;
            }
        }
        .svg__number_icon-small {
            >img {
                height: 26px !important;
                width: 26px !important;
                border-radius: 28px;
            }
        }
        .svg__number_icon-medium {
            >img {
                height: 32px !important;
                width: 32px !important;
                border-radius: 28px;
            }
        }
        .svg__number_icon-large {
            >img {
                height: 42px !important;
                width: 42px !important;
                border-radius: 28px;
            }
        }
    }

    .tacticsboard__tools_modal_container {
        height: 100vh !important;
        margin-top: -20px !important;
        padding: 12px 16px !important;
    }

    #pitchContainerBox {
        margin-top: 0px;
    }
    .tacticsboard__modal_wrap {
        .ant-modal-header {
            right: 0px;
            position: absolute !important;
            display: flex !important;
            width: 60px !important;
            height: 100vh !important;
            padding: 5px 0px !important;
            background-color: #FFFFFF !important;
            justify-content: center;
        }
        .ant-modal {
            top: 0px !important;
            margin: 0px auto !important;
        }
        .ant-modal-close {
            display: none !important;
        }
        .ant-modal.tacticsboard__modal {
            .ant-modal-header {
                padding: 5px 0px !important;
            }
        }
    }

    .tacticsboard__header_lft_extra_mb_container {
        display: flex;
        z-index: 500;
        position: absolute;
        margin-bottom: -64px;
        left: calc(50% - 16rem);
        top: calc(10% - 1rem);
        transform: translate(-50%, 50%);
        padding: 5px 15px 5px 5px;
        background: #6E6DD3;
        border-radius: 10px;
        opacity: 0.8;
    }

    .dragable-span {
        margin-left: 8px;
        border-left: 2px solid white;
        padding-left: 5px;
    }

    .dragable-span img {
        margin-top: 3px;
    }

    .menu_button {
        display: block;
    }
    .fullScreen_btn {
        padding: 10px;
    }

    .ant-drawer-close>.anticon-close svg {
        background-color: red;
        border-radius: 10px;
        padding: 2px;
        color: #fff;
    }

    //@supports (-webkit-touch-callout: none) {
    //    // Your CSS rules here
    //    display: none !important;
    //}

    @supports not (-webkit-touch-callout: none) {
        .fullScreen_button {
            display: block !important;
        }
    }
}

//BasketBall Pitches

.canvas-container.basketBall_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p1.jpg);
}

.canvas-container.basketBall_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p2.jpg);
}

.canvas-container.line_basketBall_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p1.jpg);
}

.canvas-container.line_basketBall_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/basketBall_p2.jpg);
}
//Futsal Pitches

.canvas-container.futsal_p11 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p11.jpg);
}

.canvas-container.futsal_p12 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p12.jpg);
}
.canvas-container.futsal_p13 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p13.jpg);
}

.canvas-container.line_futsal_p11 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p11.jpg);
}

.canvas-container.line_futsal_p12 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p12.jpg);
}
.canvas-container.line_futsal_p13 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/futsal_p13.jpg);
}

//Default Pitches
.canvas-container.default_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p1.png);
}

.canvas-container.default_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p2.png);
}

.canvas-container.default_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p3.png);
}

.canvas-container.light_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p4.png);
}

.canvas-container.default_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p5.png);
}

.canvas-container.default_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p6.png);
}

.canvas-container.default_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p7.png);
}

.canvas-container.default_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p8.png);
}

.canvas-container.default_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/default_p9.png);
}
//Default line  Pitches
.canvas-container.line_default_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p1.png);
}

.canvas-container.line_default_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p2.png);
}

.canvas-container.line_default_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p3.png);
}

.canvas-container.line_default_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p4.png);
}

.canvas-container.line_default_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p5.png);
}

.canvas-container.line_default_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p6.png);
}

.canvas-container.line_default_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p7.png);
}

.canvas-container.line_default_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p8.png);
}

.canvas-container.line_default_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_default_p9.png);
}

//Light Pitches

.canvas-container.light_p0 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/football_pitch_horizontal.jpeg);
}

.canvas-container.light_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p1.jpg);
}

.canvas-container.light_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p2.jpg);
}

.canvas-container.light_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p3.jpg);
}

.canvas-container.light_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p4.jpg);
}

.canvas-container.light_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p5.jpg);
}

.canvas-container.light_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p6.jpg);
}

.canvas-container.light_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p7.jpg);
}

.canvas-container.light_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p8.jpg);
}

.canvas-container.light_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/light_p9.jpg);
}


//Light Line Pitches

.canvas-container.light_p0 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/football_pitch_horizontal.jpeg);
}

.canvas-container.line_light_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p1.jpg);
}

.canvas-container.line_light_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p2.jpg);
}

.canvas-container.line_light_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p3.jpg);
}

.canvas-container.line_light_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p4.jpg);
}

.canvas-container.line_light_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p5.jpg);
}

.canvas-container.line_light_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p6.jpg);
}

.canvas-container.line_light_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p7.jpg);
}

.canvas-container.line_light_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p8.jpg);
}

.canvas-container.line_light_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_light_p9.jpg);
}



//Dark Pitches

.canvas-container.dark_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p1.jpg);
}

.canvas-container.dark_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p2.jpg);
}

.canvas-container.dark_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p3.jpg);
}

.canvas-container.dark_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p4.jpg);
}

.canvas-container.dark_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p5.jpg);
}

.canvas-container.dark_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p6.jpg);
}

.canvas-container.dark_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p7.jpg);
}

.canvas-container.dark_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p8.jpg);
}

.canvas-container.dark_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/dark_p9.jpg);
}


//Dark Line Pitches

.canvas-container.line_dark_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p1.jpg);
}

.canvas-container.line_dark_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p2.jpg);
}

.canvas-container.line_dark_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p3.jpg);
}

.canvas-container.line_dark_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p4.jpg);
}

.canvas-container.line_dark_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p5.jpg);
}

.canvas-container.line_dark_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p6.jpg);
}

.canvas-container.line_dark_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p7.jpg);
}

.canvas-container.line_dark_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p8.jpg);
}

.canvas-container.line_dark_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_dark_p9.jpg);
}


//white Pitches

.canvas-container.white_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p1.jpg);
}

.canvas-container.white_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p2.jpg);
}

.canvas-container.white_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p3.jpg);
}

.canvas-container.white_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p4.jpg);
}

.canvas-container.white_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p5.jpg);
}

.canvas-container.white_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p6.jpg);
}

.canvas-container.white_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p7.jpg);
}

.canvas-container.white_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p8.jpg);
}

.canvas-container.white_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/white_p9.jpg);
}


//white Line Pitches

.canvas-container.line_white_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p1.jpg);
}

.canvas-container.line_white_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p2.jpg);
}

.canvas-container.line_white_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p3.jpg);
}

.canvas-container.line_white_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p4.jpg);
}

.canvas-container.line_white_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p5.jpg);
}

.canvas-container.line_white_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p6.jpg);
}

.canvas-container.line_white_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p7.jpg);
}

.canvas-container.line_white_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p8.jpg);
}

.canvas-container.line_white_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_white_p9.jpg);
}

//deepGreen Line PideepGreen

.canvas-container.line_deepGreen_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p1.jpg);
}

.canvas-container.line_deepGreen_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p2.jpg);
}

.canvas-container.line_deepGreen_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p3.jpg);
}

.canvas-container.line_deepGreen_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p4.jpg);
}

.canvas-container.line_deepGreen_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p5.jpg);
}

.canvas-container.line_deepGreen_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p6.jpg);
}

.canvas-container.line_deepGreen_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p7.jpg);
}

.canvas-container.line_deepGreen_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p8.jpg);
}

.canvas-container.line_deepGreen_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/line_deepGreen_p9.jpg);
}

//deepGreen

.canvas-container.deepGreen_p1 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p1.jpg);
}

.canvas-container.deepGreen_p2 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p2.jpg);
}

.canvas-container.deepGreen_p3 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p3.jpg);
}

.canvas-container.deepGreen_p4 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p4.jpg);
}

.canvas-container.deepGreen_p5 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p5.jpg);
}

.canvas-container.deepGreen_p6 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p6.jpg);
}

.canvas-container.deepGreen_p7 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p7.jpg);
}

.canvas-container.deepGreen_p8 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p8.jpg);
}

.canvas-container.deepGreen_p9 {
    background-image: url(https://easycoach.s3.eu-central-1.amazonaws.com/animation/assets/images/deepGreen_p9.jpg);
}
